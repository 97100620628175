import { createSlice } from "@reduxjs/toolkit";
// utils
import { default as ax } from "../../utils/axiosinstance";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  users: [],
  currentUser:null,
  comments: [],
  user:{},
  userList: [],
  isOpenModal      : false ,
  
  
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  
  
    // GET USER
    getUserSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },
    // GET USER
    setCurrentUser(state, action) {
      state.isLoading = false;
      state.currentUser = action.payload;
    },
  
  
    
    // GET USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },
  
    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },
  
  
    // GET Comments
    getCommentsSuccess(state, action) {
      state.isLoading = false;
      state.comments = action.payload;
    },
  
    // OPEN MODAL
    openModal( state ) {
      state.isOpenModal = true;
    } ,
  
    // CLOSE MODAL
    closeModal( state ) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    } ,
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {                  openModal , closeModal ,
               onToggleFollow, deleteUser ,setCurrentUser} = slice.actions;

// ----------------------------------------------------------------------



export function getUserList( limit =1000000) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ax.get(`user`);
      dispatch(slice.actions.getUserListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getUser(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ax.get(`user/${id}`);
      dispatch(slice.actions.getUserSuccess(response.data));
    } catch (error) {
      
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function getComments(type) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ax.get(`comments?type=${type}`);
      dispatch(slice.actions.getCommentsSuccess(response.data.result ));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function verifyComments(id,type ) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ax.put(`comments/${id}`,{
        approved:true
      })
      dispatch(getComments(type));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function deleteComments(id ,type) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ax.delete(`comments/${id}`)
      dispatch( getComments(type) );
      
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}