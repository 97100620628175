import { createSlice } from "@reduxjs/toolkit";
// utils
import { default as ax } from "../../utils/axiosinstance";

// ----------------------------------------------------------------------

const initialState = {
     isLoading: false,
     error: false,
     logsList: [],
     isOpenModal      : false ,
     
};

const slice = createSlice({
     name: 'report',
     initialState,
     reducers: {
          // START LOADING
          startLoading(state) {
               state.isLoading = true;
          },
          
          // HAS ERROR
          hasError(state, action) {
               state.isLoading = false;
               state.error = action.payload;
          },
          
   
          // GET LOGS
          getLogsListSuccess(state, action) {
               state.isLoading = false;
               state.logsList = action.payload;
          },
     
          // OPEN MODAL
          openModal( state ) {
               state.isOpenModal = true;
          } ,
     
          // CLOSE MODAL
          closeModal( state ) {
               state.isOpenModal = false;
               state.selectedEventId = null;
               state.selectedRange = null;
          } ,
     }
});

// Reducer
export default slice.reducer;

// Actions


export const {   openModal , closeModal} = slice.actions;


// ----------------------------------------------------------------------

export function getLogsList( limit=1000,page=0 , sortBy='name',sortOrder='asc',search='') {
     return async (dispatch) => {
          dispatch(slice.actions.startLoading());
          try {
              
               const response = await ax.get(`report?limit=${limit}&skip=${page}&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${search}`);
               dispatch(slice.actions.getLogsListSuccess(response.data));
          } catch (error) {
               dispatch(slice.actions.hasError(error));
          }
     };
}
