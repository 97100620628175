import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
// material
import { alpha , styled } from "@mui/material/styles";
import {
  AlertTitle ,
  AppBar ,
  Box ,
  Button ,
  IconButton ,
  Stack ,
  Toolbar
} from "@mui/material";
// hooks
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
// components
import { MHidden } from "../../components/@material-extend";
import AccountPopover from "./AccountPopover";
import { useSelector } from "../../redux/store";
import Alert from "../../theme/overrides/Alert";
import plusFill from "@iconify/icons-eva/plus-fill";
import axios from "../../utils/axiosinstance";
import { getCategory } from "../../redux/slices/product";
import { useSnackbar } from "notistack";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { isCollapse } = useCollapseDrawer();
  const {
          serviceWorkerRegistration ,
          serviceWorkerInitialized ,
          serviceWorkerUpdated
        } = useSelector( ( state ) => state.pwa );
  const { enqueueSnackbar } = useSnackbar();
  
  const updateServiceWorker = () => {
    const registrationWaiting = serviceWorkerRegistration.waiting;
    
    if ( registrationWaiting ) {
      registrationWaiting.postMessage( { type : "SKIP_WAITING" } );
      
      registrationWaiting.addEventListener( "statechange" , e => {
        if ( e.target.state === "activated" ) {
          window.location.reload();
        }
      } );
    }
  };
  const handleSeo = async () => {
    try {

      for (const id of [1, 2, 3, 4, 5, 6]) {
        const res = await fetch(`https://api.fooladhadad.com/sitemap/create/${id}`);
      
        if (res.status === 200) {
          enqueueSnackbar(`سئو ${id} با موفقیت انجام شد.`, { variant: "success" });
        } else {
          console.error(`Error with request for ID ${id}: ${res.status}`);
        }
      }
    } catch (error) {
      console.error('Error in handleSeo:', error);
    }
  };


  
  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
        })
      }}
    >
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        {/*<Searchbar />*/}
        <Box sx={{ flexGrow: 1 }} />
        { serviceWorkerUpdated && (
       
             <Alert onClick = { updateServiceWorker }
                  severity = "success" sx = { {
               position : "fixed" , top : "10px" , left : "10px"
               , color  : "#1A1A19",cursor:"pointer"
             } } >
               <AlertTitle sx = { {
                 color      : "#1A1A19"
               } } >نسخه جدید </AlertTitle >
               <strong style = { {
                 color      : "#1A1A19"
               } } > برای امتحان کلیک کنید !</strong >
             </Alert >
        )}
        
        <Button sx = { {
          mr             : 2 ,
       
        } }
             variant = "contained"
             onClick = {handleSeo}
        >
          رفرش سئو
        </Button >
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/*<NotificationsPopover />*/}
          {/*<ContactsPopover />*/}
          <AccountPopover />
        </Stack>
     
      </ToolbarStyle>
    </RootStyle>
  );
}
