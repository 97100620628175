// utils


import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axiosinstance";

const initialState = {
     isLoading: false,
     error: false,
    weekly:[],
     year:[]
};


const slice = createSlice({
     name: 'chart',
     initialState,
     reducers: {
          startLoading(state) {
               state.isLoading = true;
          },
     
          // HAS ERROR
          hasError(state, action) {
               state.isLoading = false;
               state.error = action.payload;
          },
     
     
     
          getDataChartsWeeklySuccess(state, action) {
               state.isLoading = false;
               state.weekly = action.payload;
          },
          getDataChartsYearSuccess(state, action) {
               state.isLoading = false;
               state.year = action.payload;
          },
     
     }
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function getChartsWeekly(from ,to) {
     return async (dispatch) => {
          dispatch(slice.actions.startLoading());
          try {
               const response = await axios.get(`/order/chart?from=${from}&to=${to}`);
               dispatch(slice.actions.getDataChartsYearSuccess(response.data.result.ordersDetails));
          } catch (error) {
               dispatch(slice.actions.hasError(error));
          }
     };
}

export function getChartsYear(from ,to) {
     return async (dispatch) => {
          dispatch(slice.actions.startLoading());
          try {
               const response = await axios.get(`/order/chart?from=${from}&to=${to}`);
               dispatch(slice.actions.getDataChartsYearSuccess(response.data.result.ordersDetails));
          } catch (error) {
               dispatch(slice.actions.hasError(error));
          }
     };
}