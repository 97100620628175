import { createContext , useEffect , useReducer } from "react";
import PropTypes from "prop-types";
// utils
import { setSession } from "../utils/jwt";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "../redux/store";
import axios from "../utils/axiosinstance";
import {  setCurrentAdmin } from "../redux/slices/admin";

// ----------------------------------------------------------------------

const initialState = {
     isAuthenticated : false ,
     isInitialized   : false ,
     user            : null
};

const handlers = {
     INITIALIZE : ( state , action ) => {
          const { isAuthenticated , user } = action.payload;
          return {
               ...state ,
               isAuthenticated ,
               isInitialized : true ,
               user
          };
     } ,
     LOGIN      : ( state , action ) => {
          const { user } = action.payload;
          return {
               ...state ,
               isAuthenticated : true ,
               user
          };
     } ,
     LOGOUT     : ( state ) => ({
          ...state ,
          isAuthenticated : false ,
          user            : null
     }) ,
     REGISTER   : ( state , action ) => {
          const { user } = action.payload;
          
          return {
               ...state ,
               isAuthenticated : true ,
               user
          };
     }
};

const reducer = ( state , action ) => (handlers[ action.type ] ? handlers[ action.type ]( state , action ) : state);

const AuthContext = createContext( {
     ...initialState ,
     method   : "jwt" ,
     login    : () => Promise.resolve() ,
     logout   : () => Promise.resolve() ,
     register : () => Promise.resolve()
} );

AuthProvider.propTypes = {
     children : PropTypes.node
};

function AuthProvider( { children } ) {
     const [ state , dispatch ] = useReducer( reducer , initialState );
     const navigate = useNavigate();
     const dis = useDispatch();
     useEffect( () => {
          const initialize = async () => {
               try {
                    const accessToken = window.localStorage.getItem( "accessToken" );
                    if ( accessToken ) {
                         setSession( accessToken );
                         const response = await axios.get( "admin/current" );
                         const { admin } = response.data;
                         const { status } = response;
                        
                         
                         if ( status === 400 ) {
                              dispatch( {
                                   type    : 'INITIALIZE' ,
                                   payload : {
                                        isAuthenticated : false ,
                                        user            : null
                                   }
                              } );
                         }  else if(status === 200) {
                              const user = {
                                   email:admin.email,
                                   role     : admin.isGodAdmin ? " سوپر ادمین"  : "ادمین معمولی" ,
                                   photoURL : '/static/mock-images/avatars/avatar_default.jpg'
                              };
                              dis(setCurrentAdmin(admin))
                              dispatch( {
                                   type    : "INITIALIZE" ,
                                   payload : {
                                        isAuthenticated : true ,
                                        user
                                   }
                              } );
                         }
                    }else {
                         dispatch( {
                              type    : "INITIALIZE" ,
                              payload : {
                                   isAuthenticated : false ,
                                   user            : null
                              }
                         } );
                    }
               } catch (err) {
                    console.error( err );
                    dispatch( {
                         type    : "INITIALIZE" ,
                         payload : {
                              isAuthenticated : false ,
                              user            : null
                         }
                    } );
               }
          };
          
          
          initialize();
     } , [] );
     
     const login = async ( userName , pass ) => {
          
          const response = await axios.post( "https://api.fooladhadad.com/panel/admin/login" , {
              email: userName ,
               password :pass
          }  );
         
          const { token ,admin,admin:{ email , isGodAdmin,isSuperAdmin ,name, permissions}} = response.data;
          const { status} = response;
          
          
          const user = {
               email    : email ,
               role     : isGodAdmin ? " سوپر ادمین"  : "ادمین معمولی" ,
               photoURL : "/static/mock-images/avatars/avatar_default.jpg"
          };
          if( response.data !== null) {
               dis( setCurrentAdmin( admin ) );
               setSession( token );
               const accessToken1 = window.localStorage.getItem( "accessToken" );
     
               if ( status === 200 && accessToken1 ) {
                    dispatch( {
                         type    : "LOGIN" ,
                         payload : {
                              user
                         }
                    } )
                    return  status
               } else  {
                    return status
               }
          }
          

     
     
    
          
     };
     
     const register = async ( email , password , firstName , lastName ) => {
          const response = await axios.post( "/api/account/register" , {
               email ,
               password ,
               firstName ,
               lastName
          } );
          const { token , user } = response.data;
          
          window.localStorage.setItem( "accessToken" , token );
          dispatch( {
               type    : "REGISTER" ,
               payload : {
                    user
               }
          } );
     };
     
     const logout = async () => {
          setSession( null );
          dispatch( { type : "LOGOUT" } );
     };
     
     const resetPassword = () => {};
     
     const updateProfile = () => {};
     
     return (
          <AuthContext.Provider
               value = { {
                    ...state ,
                    method : "jwt" ,
                    login ,
                    logout ,
                    register ,
                    resetPassword ,
                    updateProfile
               } }
          >
               { children }
          </AuthContext.Provider >
     );
}

export { AuthContext , AuthProvider };
