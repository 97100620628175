import { createSlice } from "@reduxjs/toolkit";
// utils

// ----------------------------------------------------------------------

const initialState = {
     isLoading: false,
     error: false,
     serviceWorkerInitialized: false,
     serviceWorkerUpdated: false,
     serviceWorkerRegistration: null,
};

const slice = createSlice({
     name: 'pwa',
     initialState,
     reducers: {
          // START LOADING
          startLoading(state) {
               state.isLoading = true;
          },
          
          // HAS ERROR
          hasError(state, action) {
               state.isLoading = false;
               state.error = action.payload;
          },
     
          setServiceWorkerInitialized(state, action) {
               state.serviceWorkerInitialized = !state.serviceWorkerInitialized;
          },
          setServiceWorkerUpdated(state, action) {
               state.serviceWorkerUpdated = !state.serviceWorkerUpdated;
               state.serviceWorkerRegistration=action.payload;
     
          },
      /*    setServiceWorkerRegistration(state, action) {
               state.isLoading = false;
               state.serviceWorkerRegistration = action.payload;
          },
          */
     }
});

// Reducer
export default slice.reducer;

// Actions
export const { setServiceWorkerInitialized ,setServiceWorkerUpdated } = slice.actions;

// ----------------------------------------------------------------------
