import { filter } from "lodash";
import { Icon } from "@iconify/react";
import React , { useEffect , useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink , useNavigate } from "react-router-dom";

// material
import { styled } from "@mui/material/styles";
import {
     Box ,
     Button ,
     Card ,
     Checkbox ,
     Container ,
     DialogTitle ,
     Stack ,
     Table ,
     TableBody ,
     TableCell ,
     TableContainer ,
     TablePagination ,
     TableRow ,
     Typography
} from "@mui/material";
// redux
import { useDispatch , useSelector } from "../../../redux/store";
import {
     closeModal ,
     deleteAttri ,
     getAttributes ,
     getCategory , getDiscount ,
     openModal
} from "../../../redux/slices/product";
// utils
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// hooks
import useSettings from "../../../hooks/useSettings";
// components
import Page from "../../../components/Page";
import Scrollbar from "../../../components/Scrollbar";
import SearchNotFound from "../../../components/SearchNotFound";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import { ListHead , ListToolbar , MoreMenu } from "../../../components/_dashboard/tables";
import { DialogAnimate } from "../../../components/animate";
import LoadingScreen from "../../../components/LoadingScreen";
import axios from "../../../utils/axiosinstance";
import { useSnackbar } from "notistack";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
     // { id : "title" , label : "عنوان " , alignRight : false } ,
     { id : "product" , label : "محصول" , alignRight : false } ,
     { id : "subcatgory" , label : "زیردسته" , alignRight : false } ,
     { id : "percentage" , label : "درصد" , alignRight : false } ,
     { id : "discountStatus" , label : "وضعیت" , alignRight : false } ,
     
     { id : "" }
];


// ----------------------------------------------------------------------

function descendingComparator( a , b , orderBy ) {
     
          if (a && b && b[ orderBy ] < a[ orderBy ] ) {
               return -1;
          }
          if (a && b && b[ orderBy ] > a[ orderBy ] ) {
               return 1;
          }
     
     return 0;
}

function getComparator( order , orderBy ) {
     return order === "desc"
          ? ( a , b ) => descendingComparator( a , b , orderBy )
          : ( a , b ) => -descendingComparator( a , b , orderBy );
}

function applySortFilter( array , comparator , query ) {
     const stabilizedThis = array.map( ( el , index ) => [ el , index ] );
     stabilizedThis.sort( ( a , b ) => {
          const order = comparator( a[ 0 ] , b[ 0 ] );
          if ( order !== 0 ) return order;
          return a[ 1 ] - b[ 1 ];
     } );
     
     if ( query ) {
          return filter( array , ( _َattr ) => _َattr.fa.name.toLowerCase().indexOf( query.toLowerCase() ) !== -1 );
     }
     
     return stabilizedThis.map( ( el ) => el[ 0 ] );
}

// ----------------------------------------------------------------------

export default function EcommerceDiscountList() {
     const { themeStretch } = useSettings();
     const dispatch = useDispatch();
     const {
                isLoading ,
                isOpenModal ,
                attributesList ,productsDiscountList,
                productsDiscountList : { discounts:pro }
           } = useSelector( ( state ) => state.product );
     const [ page , setPage ] = useState( 0 );
     let navigate = useNavigate();
     const [ order , setOrder ] = useState( "asc" );
     const [ selected , setSelected ] = useState( [] );
     const [ filterName , setFilterName ] = useState( "" );
     const [ rowsPerPage , setRowsPerPage ] = useState( 10 );
     const [ orderBy , setOrderBy ] = useState( "name" );
     const [ isTypeFinished , setIsTypeFinished ] = useState( false );
     let timer;
     const [ deleteState , setDeleteState ] = useState( "all" );
     const { enqueueSnackbar } = useSnackbar();
     
     const fakeRows=[ ...Array( rowsPerPage*page ) ]
     const products=pro && pro.length >=1 && fakeRows.concat(pro) || [];
     
     useEffect( () => {
          dispatch( getDiscount( rowsPerPage , rowsPerPage * page , orderBy , order , filterName ) );
          handleCloseModal()
          
     } , [ dispatch , page , rowsPerPage , isTypeFinished , orderBy , order ] );
     
     const handleOpenModal = ( state ) => {
          setDeleteState( state )
          dispatch( openModal() );
     }
     const handleCloseModal = () => {
          dispatch( closeModal() );
     };
     
     
     const handleRequestSort = ( event , property ) => {
          const isAsc = orderBy === property && order === "asc";
          setOrder( isAsc ? "desc" : "asc" );
          setOrderBy( property );
     };
     
     
     const handleSelectAllClick = ( event ) => {
          if ( event.target.checked ) {
               const newSelecteds = products.map( ( n ) => n._id );
               setSelected( newSelecteds );
               return;
          }
          setSelected( [] );
     };
     
     const handleClick = ( event , id ) => {
          
          const selectedIndex = selected.indexOf( id );
          let newSelected = [];
          if ( selectedIndex === -1 ) {
               newSelected = newSelected.concat( selected , id );
          } else if ( selectedIndex === 0 ) {
               newSelected = newSelected.concat( selected.slice( 1 ) );
          } else if ( selectedIndex === selected.length - 1 ) {
               newSelected = newSelected.concat( selected.slice( 0 , -1 ) );
          } else if ( selectedIndex > 0 ) {
               newSelected = newSelected.concat( selected.slice( 0 , selectedIndex ) , selected.slice( selectedIndex + 1 ) );
          }
          setSelected( newSelected );
     };
     
     const handleChangePage = ( event , newPage ) => {
          setPage( newPage );
     };
     
     const handleChangeRowsPerPage = ( event ) => {
          setRowsPerPage( parseInt( event.target.value , 10 ) );
          setPage( 0 );
     };
     
     const handleFilterByName = ( event ) => {
          
          clearTimeout( timer );
          setFilterName( event.target.value );
          timer = setTimeout( () => {
               setIsTypeFinished( !isTypeFinished )
          } , 500 );
     };
     
     
     const handleDelete = async ( id ) => {
         try {
               const res = await axios.post( `discount/delete` , { idList : [ id ] } );
               if( res.status === 200 ) {
                    enqueueSnackbar( "حذف با موفقیت انجام شد. " , { variant : "success" } );
               }
               dispatch( getDiscount( rowsPerPage , rowsPerPage * page , orderBy , order , filterName ) );
               handleCloseModal()
               setSelected( [] )
          }catch (error) {
              enqueueSnackbar( error , { variant : "error" } );
              handleCloseModal()
              setSelected( [] )
         }
          
     };
     
     async function deleteSelect() {
          try{
               const res = await axios.post( `discount/delete` , { idList : selected } );
               if( res.status === 200 ) {
                    enqueueSnackbar( "حذف با موفقیت انجام شد. " , { variant : "success" } );
               }
               dispatch( getDiscount( rowsPerPage , rowsPerPage * page , orderBy , order , filterName ) );
               handleCloseModal()
               setSelected( [] )
          }catch (error) {
               enqueueSnackbar( error , { variant : "error" } );
               handleCloseModal()
               setSelected( [] )
          }
          
     }
     
     const emptyRows = page > 0 ? Math.max( 0 , (1 + page) * rowsPerPage - products.length ) : 0;
     
     const filteredProducts = products ? applySortFilter( products , getComparator( order , orderBy ) , filterName ) : [];
     
     const isProductNotFound = filteredProducts.length === 0;
     
     return (
          <Page title = "فروشگاه : لیست تخفیفی ها | پنل ادمین" >
               <Container maxWidth = { themeStretch ? false : "lg" } >
                    <HeaderBreadcrumbs
                         heading = "لیست تخفیفی ها"
                         links = { [
                              { name : "داشبورد" , href : PATH_DASHBOARD.root } ,
                              {
                                   name : "فروشگاه" ,
                                   href : PATH_DASHBOARD.eCommerce.root
                              } ,
                              { name : "لیست تخفیفی ها" }
                         ] }
                         action = {
                              <Button
                                   variant = "contained"
                                   component = { RouterLink }
                                   to = { PATH_DASHBOARD.eCommerce.discount }
                                   startIcon = { <Icon icon = { plusFill } /> }
                              >
                                   ساخت تخفیف جدید
                              </Button >
                         }
                    />
                    {
                         isLoading ?
                              <LoadingScreen />
                              :
                              <>
                                   <DialogAnimate open = { isOpenModal } onClose = { handleCloseModal } >
                                        <DialogTitle >آیا از عملیات اطمینان دارید ؟</DialogTitle >
                                        <Stack direction = { { xs : "column" , sm : "row" } }
                                             spacing = { { xs : 4 , sm : 3 } } sx = { { m : 3 } } >
                                             <Button variant = "outlined" fullWidth
                                                  size = "large" onClick = { handleCloseModal } >
                                                  انصراف
                                             </Button >
                                             
                                             <Button variant = "contained" fullWidth size = "large"
                                                  onClick = { deleteState === "all" ? deleteSelect : handleDelete } >
                                                  تایید
                                             </Button >
                                        
                                        </Stack >
                                   
                                   </DialogAnimate >
                                   <Card >
                                        <ListToolbar onDelete = { () => handleOpenModal( "all" ) }
                                             numSelected = { selected.length } filterName = { filterName }
                                             onFilterName = { handleFilterByName } />
                                        
                                        <Scrollbar >
                                             <TableContainer sx = { { minWidth : 800 } } >
                                                  <Table >
                                                       <ListHead
                                                            order = { order }
                                                            orderBy = { orderBy }
                                                            headLabel = { TABLE_HEAD }
                                                            rowCount = { products?.length }
                                                            numSelected = { selected.length }
                                                            onRequestSort = { handleRequestSort }
                                                            onSelectAllClick = { handleSelectAllClick }
                                                       />
                                                       <TableBody >
                                                            {  products?.length >= 1 && filteredProducts.slice( page * rowsPerPage , page * rowsPerPage + rowsPerPage ).map( ( row ) => {
                                                                 const { id , _id , title ,values,percentage,discountStatus,product,subcategory } = row;
                                                                 const isItemSelected = selected.indexOf( _id ) !== -1;
                                                                
                                                                 return (
                                                                      
                                                                      <TableRow
                                                                           hover
                                                                           key = { id }
                                                                           tabIndex = { -1 }
                                                                           role = "checkbox"
                                                                           selected = { isItemSelected }
                                                                           aria-checked = { isItemSelected }
                                                                      >
                                                                           <TableCell padding = "checkbox" >
                                                                                <Checkbox
                                                                                     checked = { isItemSelected }
                                                                                     onChange = { ( event ) => handleClick( event , _id ) } />
                                                                           </TableCell >
                                                                           <TableCell component = "th"
                                                                                scope = "row" padding = "none"
                                                                                onClick = { () => navigate( `${ PATH_DASHBOARD.eCommerce.root }/discount/${ _id }/edit` ) } >
                                                                                <Box
                                                                                     sx = { {
                                                                                          py         : 2 ,
                                                                                          display    : "flex" ,
                                                                                          alignItems : "center" ,
                                                                                          cursor     : "pointer"
                                                                                     } }
                                                                                >
                                                                                     <Typography
                                                                                          variant = "subtitle2"
                                                                                          noWrap >
                                                                                          {product.name}
                                                                                     
                                                                                     </Typography >
                                                                                </Box >
                                                                           </TableCell >
                                                                        {/*   <TableCell
                                                                                style = { { minWidth : 260 } } >
                                                                                {product.name}
                                                                           </TableCell >*/}
                                                                           <TableCell
                                                                                style = { { minWidth : 160 } } >
                                                                                {subcategory.name}
                                                                           </TableCell >
                                                                           <TableCell
                                                                                style = { { minWidth : 160 } } >
                                                                                {percentage}
                                                                           </TableCell >
                                                                              <TableCell
                                                                                style = { { minWidth : 160 } } >
                                                                                {discountStatus ? "تخفیف دارد" :
                                                                                "تخفیف ندارد"}
                                                                           </TableCell >
                                                                           
                                                                           
                                                                           <TableCell align = "right" >
                                                                                
                                                                                <MoreMenu
                                                                                     onDelete = { () => handleDelete( _id ) }
                                                                                     link = { `${ PATH_DASHBOARD.eCommerce.root }/discount/${ _id }/edit` }
                                                                                />
                                                                           </TableCell >
                                                                      </TableRow >
                                                                 );
                                                            } ) }
                                                            { emptyRows > 0 && (
                                                                 <TableRow
                                                                      style = { { height : 53 * emptyRows } } >
                                                                      <TableCell colSpan = { 6 } />
                                                                 </TableRow >
                                                            ) }
                                                       </TableBody >
                                                       { isProductNotFound && (
                                                            <TableBody >
                                                                 <TableRow >
                                                                      <TableCell align = "center"
                                                                           colSpan = { 6 } >
                                                                           <Box sx = { { py : 3 } } >
                                                                                <SearchNotFound
                                                                                     searchQuery = { filterName } />
                                                                           </Box >
                                                                      </TableCell >
                                                                 </TableRow >
                                                            </TableBody >
                                                       ) }
                                                  </Table >
                                             </TableContainer >
                                        </Scrollbar >
                                        <TablePagination
                                             rowsPerPageOptions = { [ 10 , 25 , 100 , 1000 ] }
                                             component = "div"
                                             labelRowsPerPage = { "سطرها در هر صفحه" }
                                             count = { attributesList?.count || 0}
                                             labelDisplayedRows = {
                                                  ( { from , to , count } ) => {
                                                       return "" + from + "-" + to + " از " + count;
                                                  }
                                             }
                                             rowsPerPage = { rowsPerPage }
                                             page = { page }
                                             onPageChange = { handleChangePage }
                                             onRowsPerPageChange = { handleChangeRowsPerPage }
                                        />
                                   </Card >
                              </>
                         
                         
                    }
               </Container >
          </Page >
     );
}
