import { useEffect } from "react";
import { useLocation , useParams } from "react-router-dom";
// material
import { Container } from "@mui/material";
// redux
import { useDispatch , useSelector } from "../../../redux/store";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// hooks
import useSettings from "../../../hooks/useSettings";
// components
import Page from "../../../components/Page";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import { getCurrentDiscount } from "../../../redux/slices/product";
import LoadingScreen from "../../../components/LoadingScreen";
import DiscountNewForm
   from "../../../components/_dashboard/e-commerce/discount/DiscountNewForm";

// ----------------------------------------------------------------------

export default function EcommerceDiscountCreate() {
     const { themeStretch } = useSettings();
     const dispatch = useDispatch();
     const { pathname } = useLocation();
     const { id } = useParams();
     const {isLoading,currentDiscount} = useSelector((state) => state.product);
     const isEdit = pathname.includes('edit');
     useEffect(() => {
          dispatch( getCurrentDiscount( id ) );
     }, [dispatch]);
     
     return (
          <Page title="فروشگاه : ساخت و یرایش تخفیف ">
               <Container maxWidth={themeStretch ? false : 'lg'}>
                    <HeaderBreadcrumbs
                         heading={!isEdit ? 'ساخت تخفیف جدید' : 'ویرایش تخفیف '}
                         links={[
                              { name: 'داشبورد', href: PATH_DASHBOARD.root },
                              { name: 'تخفیف ها', href: PATH_DASHBOARD.eCommerce.attributesList },
                              { name: !isEdit ? 'تخفیف جدید' : currentDiscount?.name }
                         ]}
                    />
                    {
                         isLoading ?
                              <LoadingScreen/>
                              :
                           <DiscountNewForm  currentDiscount={ currentDiscount} isEdit={isEdit}/>
     
                    }
               </Container>
          </Page>
     );
}
