import PropTypes from "prop-types";
// material
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

LoginSVG.propTypes = {
     sx: PropTypes.object
};

export default function LoginSVG({ sx }) {
     const theme = useTheme();
     const PRIMARY_LIGHT = theme.palette.primary.light;
     const PRIMARY_MAIN = theme.palette.primary.main;
     const PRIMARY_DARK = theme.palette.primary.dark;
     const GERY_LIGHT_2 = theme.palette.grey[100];
     const GERY_LIGHT = theme.palette.grey[300];
     const GERY = theme.palette.grey[600];
     const GERY_DARK = theme.palette.grey[900];
     
     return (
          <Box >
               <svg width="400" height="400" viewBox="0 0 552 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_dd_114_786)">
                         <path d="M469.276 271.434C511.922 271.434 546.493 236.863 546.493 194.217C546.493 151.571 511.922 117 469.276 117C426.63 117 392.059 151.571 392.059 194.217C392.059 236.863 426.63 271.434 469.276 271.434Z" fill={PRIMARY_MAIN} stroke={PRIMARY_MAIN} stroke-width="4"/>
                    </g>
                    <g filter="url(#filter1_dd_114_786)">
                         <path d="M226.847 370.814C234.661 366.108 244.827 368.612 249.533 376.455C254.239 384.268 251.735 394.435 243.892 399.141C236.079 403.847 225.912 401.343 221.206 393.5C216.5 385.716 219.034 375.55 226.847 370.814Z" fill="#FCC9A7"/>
                         <path d="M245.581 371.99C245.038 371.568 216.018 337.479 216.018 337.479L204.162 351.839L230.347 384.992L245.581 371.99Z" fill="#FCC9A7"/>
                         <path d="M221.116 637.763L213.725 671.52L190.376 668.02L197.918 634.716L221.116 637.763Z" fill="#FCC9A7"/>
                         <path d="M325.796 634.746L326.067 669.317L302.567 671.067L302.507 636.918L325.796 634.746Z" fill="#FCC9A7"/>
                         <path d="M216.681 666.029C216.199 664.611 214.479 664.008 213.242 664.913C210.829 666.663 206.575 668.714 200.421 667.99C194.026 667.236 191.733 663.857 190.949 661.444C190.527 660.177 189.169 659.513 187.932 659.996L148.323 675.864C145.91 676.829 143.979 678.699 142.983 681.082C142.018 683.315 143.647 685.819 146.091 685.819L213.393 686.301C216.048 686.332 218.16 684.129 218.039 681.475L217.466 668.473C217.466 668.261 217.406 668.05 217.345 667.839L216.681 666.029Z" fill={GERY_DARK}/>
                         <path d="M300.184 666.029C300.667 664.611 302.416 664.008 303.623 664.913C306.037 666.663 310.29 668.714 316.444 667.99C322.84 667.236 325.102 663.857 325.917 661.444C326.339 660.177 327.697 659.513 328.933 659.996L368.543 675.864C370.956 676.829 372.887 678.699 373.882 681.082C374.848 683.315 373.219 685.819 370.775 685.819L303.472 686.301C300.818 686.332 298.706 684.129 298.827 681.475L299.4 668.473C299.4 668.261 299.46 668.05 299.52 667.839L300.184 666.029Z" fill={GERY_DARK}/>
                         <path d="M299.882 201.335L278.977 203.145L245.732 221.879V355.097H334.454V221.879L299.882 201.335Z" fill={GERY}/>
                         <path d="M294.151 214.76H288.148C281.933 214.76 276.896 209.722 276.896 203.507V180.942H305.373V203.507C305.403 209.722 300.365 214.76 294.151 214.76Z" fill="#FCC9A7"/>
                         <path d="M276.896 192.436C276.896 192.436 281.33 201.335 291.406 201.335C301.482 201.335 305.373 192.255 305.373 192.255L276.896 192.436Z" fill="#F4BE9A"/>
                         <path d="M291.466 196.116H290.531C276.02 196.116 264.074 184.653 263.501 170.142L262.988 155.692C262.385 140.337 274.663 127.577 290.018 127.577H291.979C303.593 127.577 319.612 140.337 319.008 155.692L318.495 170.142C317.922 184.653 305.976 196.116 291.466 196.116Z" fill="#FCC9A7"/>
                         <path d="M271.796 166.643C272.128 171.53 268.448 175.753 263.561 176.085C258.674 176.417 254.45 172.737 254.118 167.849C253.787 162.962 257.467 158.739 262.354 158.407C267.241 158.106 271.464 161.786 271.796 166.643Z" fill="#FCC9A7"/>
                         <path d="M327.847 166.643C328.179 171.53 324.499 175.753 319.612 176.085C314.724 176.417 310.501 172.737 310.169 167.849C309.837 162.962 313.518 158.739 318.405 158.407C323.292 158.106 327.515 161.786 327.847 166.643Z" fill="#FCC9A7"/>
                         <path d="M245.732 354.463L190.557 647.778L222.262 647.476L302.929 355.036L245.732 354.463Z" fill={GERY_DARK}/>
                         <path d="M334.454 355.097L330.985 648.08H297.469L284.105 356.937L334.454 355.097Z" fill={GERY_DARK}/>
                         <path d="M245.733 221.879C245.733 221.879 171.642 266.587 153.633 297.598C150.103 303.662 154.688 311.505 160.903 314.733C167.781 318.323 176.258 315.88 180.21 309.213L248.749 269.453C248.779 269.453 245.733 235.303 245.733 221.879Z" fill={GERY}/>
                         <path d="M261.45 140.281L263.531 162.484H266.88L267.302 150.84C267.302 150.84 269.052 146.375 276.865 136.782C276.865 136.782 281.595 148.366 313.18 145.832L315.389 162.002L318.647 161.64L320.366 145.832C321.512 140.493 321.905 135.515 320.939 130.145C318.978 119.285 310.14 113.282 284.618 120.944C279.146 115.74 281.487 120.714 284.618 120.944C277.318 120.13 258.071 120.039 261.45 140.281Z" fill={GERY_DARK}/>
                         <path d="M299.038 352.985L291.858 429.791C291.587 432.687 293.547 435.311 296.413 435.854L384.501 452.687C387.729 453.321 390.806 451.028 391.168 447.77L399.223 374.766C399.525 371.93 397.684 369.305 394.879 368.642L305.916 348.007C302.598 347.223 299.37 349.576 299.038 352.985Z" fill="#AFAFAF"/>
                         <path d="M303.925 354.342L296.745 431.148C296.474 434.044 298.434 436.669 301.3 437.212L389.388 454.045C392.616 454.648 395.693 452.386 396.055 449.128L404.11 376.123C404.412 373.287 402.571 370.663 399.766 369.999L310.803 349.365C307.515 348.581 304.257 350.934 303.925 354.342Z" fill={GERY_LIGHT}/>
                         <path d="M349.779 432.747C358.527 435.341 363.535 444.542 360.941 453.291C358.346 462.039 349.145 467.047 340.397 464.483C331.648 461.888 326.641 452.687 329.205 443.939C331.829 435.16 341 430.153 349.779 432.747Z" fill="#FCC9A7"/>
                         <path d="M361.574 447.348C361.514 446.654 358.678 400.619 358.678 400.619L343.021 403.455L345.103 442.702L361.574 447.348Z" fill="#FCC9A7"/>
                         <path d="M334.454 221.879C334.454 221.879 358.165 238.863 359.673 263.057L367.094 319.62C367.517 322.939 367.698 326.257 367.577 329.606L364.711 415.642H332.161L331.558 322.516L334.454 221.879Z" fill={GERY}/>
                         <path d="M281.48 201.335C278.946 203.175 266.729 210.053 266.729 210.053C266.729 210.053 267.06 227.248 292.944 225.861C315.72 224.654 315.569 210.687 315.569 210.687L299.852 201.335H281.48V201.335Z" fill="#FCC9A7"/>
                         <path d="M190.134 286.437L234.42 357.209L213.423 377.089L153.753 307.433L190.134 286.437Z" fill={GERY}/>
                         <path d="M331.528 354.162V322.486L324.167 264.716" stroke="#424647" stroke-width="0.7693" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <path d="M443.747 204.965C443.208 204.965 442.659 204.83 442.158 204.542C440.622 203.663 440.086 201.702 440.965 200.166L453.798 177.707C454.247 176.918 455.011 176.36 455.897 176.164C456.776 175.972 457.706 176.161 458.447 176.687L478.297 190.865L492.472 171.018C493.501 169.571 495.507 169.244 496.947 170.27C498.391 171.3 498.725 173.305 497.695 174.746L481.653 197.204C480.623 198.645 478.615 198.978 477.177 197.952L457.607 183.973L446.535 203.348C445.944 204.385 444.86 204.965 443.747 204.965Z" fill={GERY_LIGHT_2}/>
                    <path d="M495.083 179.299C498.627 179.299 501.5 176.426 501.5 172.882C501.5 169.338 498.627 166.465 495.083 166.465C491.539 166.465 488.667 169.338 488.667 172.882C488.667 176.426 491.539 179.299 495.083 179.299Z" fill={GERY_DARK}/>
                    <path d="M479.042 201.757C482.585 201.757 485.458 198.884 485.458 195.34C485.458 191.796 482.585 188.924 479.042 188.924C475.498 188.924 472.625 191.796 472.625 195.34C472.625 198.884 475.498 201.757 479.042 201.757Z" fill={GERY_DARK}/>
                    <path d="M456.583 185.715C460.127 185.715 463 182.842 463 179.299C463 175.755 460.127 172.882 456.583 172.882C453.039 172.882 450.167 175.755 450.167 179.299C450.167 182.842 453.039 185.715 456.583 185.715Z" fill={GERY_DARK}/>
                    <path d="M443.75 208.174C447.294 208.174 450.166 205.301 450.166 201.757C450.166 198.213 447.294 195.34 443.75 195.34C440.206 195.34 437.333 198.213 437.333 201.757C437.333 205.301 440.206 208.174 443.75 208.174Z" fill={GERY_DARK}/>
                    <path d="M496.687 224.215H442.146C439.489 224.215 437.333 222.059 437.333 219.403C437.333 216.746 439.489 214.59 442.146 214.59H496.687C499.344 214.59 501.5 216.746 501.5 219.403C501.5 222.059 499.344 224.215 496.687 224.215Z" fill={GERY_DARK}/>
                    <g filter="url(#filter2_dd_114_786)">
                         <path d="M111.544 218C170.362 218 218.044 170.318 218.044 111.5C218.044 52.6817 170.362 5 111.544 5C52.7256 5 5.04395 52.6817 5.04395 111.5C5.04395 170.318 52.7256 218 111.544 218Z" fill={PRIMARY_MAIN} stroke={PRIMARY_MAIN} stroke-width="4"/>
                    </g>
                    <g filter="url(#filter3_dd_114_786)">
                         <path d="M378.473 90.3997C400.399 90.3997 418.173 72.6254 418.173 50.6997C418.173 28.774 400.399 10.9998 378.473 10.9998C356.548 10.9998 338.773 28.774 338.773 50.6997C338.773 72.6254 356.548 90.3997 378.473 90.3997Z" fill={PRIMARY_MAIN} stroke={PRIMARY_MAIN} stroke-width="4"/>
                    </g>
                    <g filter="url(#filter4_dd_114_786)">
                         <path d="M467.358 400.325C484.443 400.325 498.292 386.475 498.292 369.391C498.292 352.306 484.443 338.457 467.358 338.457C450.274 338.457 436.424 352.306 436.424 369.391C436.424 386.475 450.274 400.325 467.358 400.325Z" fill={PRIMARY_MAIN} stroke={PRIMARY_MAIN} stroke-width="4"/>
                    </g>
                    <g filter="url(#filter5_dd_114_786)">
                         <path d="M121.5 495.082C152.649 495.082 177.9 469.831 177.9 438.682C177.9 407.533 152.649 382.282 121.5 382.282C90.3512 382.282 65.1001 407.533 65.1001 438.682C65.1001 469.831 90.3512 495.082 121.5 495.082Z" fill={PRIMARY_MAIN} stroke={PRIMARY_MAIN} stroke-width="4"/>
                    </g>
                    <path d="M467.428 367.142C471.225 367.142 474.303 364.251 474.303 360.684C474.303 357.117 471.225 354.225 467.428 354.225C463.631 354.225 460.553 357.117 460.553 360.684C460.553 364.251 463.631 367.142 467.428 367.142Z" fill={GERY_LIGHT_2}/>
                    <path d="M475.678 371.017H459.178C456.899 371.017 455.053 372.752 455.053 374.892C455.053 377.032 456.899 378.767 459.178 378.767H475.678C477.956 378.767 479.803 377.032 479.803 374.892C479.803 372.752 477.956 371.017 475.678 371.017Z" fill={GERY_DARK}/>
                    <g clip-path="url(#clip0_114_786)">
                         <path d="M147.198 440.776H132.745C132.932 439.861 133.031 438.915 133.031 437.943C133.031 430.12 126.687 423.776 118.865 423.776C111.042 423.776 104.698 430.12 104.698 437.943C104.698 445.766 111.042 452.109 118.865 452.109C119.836 452.109 120.783 452.01 121.698 451.823V466.276C121.698 469.407 124.234 471.943 127.365 471.943H147.198C150.329 471.943 152.865 469.407 152.865 466.276V446.443C152.865 443.312 150.329 440.776 147.198 440.776Z" fill={GERY_LIGHT_2}/>
                         <path d="M135.865 463.443C135.139 463.443 134.414 463.165 133.861 462.612L131.028 459.779C129.92 458.671 129.92 456.881 131.028 455.773C132.136 454.665 133.927 454.665 135.034 455.773L135.865 456.603L142.361 450.106C143.469 448.998 145.26 448.998 146.368 450.106C147.476 451.214 147.476 453.005 146.368 454.112L137.868 462.612C137.315 463.165 136.59 463.443 135.865 463.443Z" fill={GERY_DARK}/>
                         <path d="M121.698 448.876C120.789 449.112 119.848 449.276 118.865 449.276C112.606 449.276 107.531 444.201 107.531 437.943C107.531 431.684 112.606 426.609 118.865 426.609C125.124 426.609 130.198 431.684 130.198 437.943C130.198 438.926 130.034 439.866 129.799 440.776H142.149C141.761 439.909 141.531 438.957 141.531 437.943C141.531 435.786 142.5 433.871 144.025 432.579C145.382 431.429 145.977 429.604 145.348 427.938C144.251 425.031 142.693 422.351 140.755 419.991C139.67 418.667 137.842 418.157 136.244 418.772C134.332 419.515 132.119 419.421 130.198 418.313C128.271 417.2 127.081 415.321 126.77 413.284C126.515 411.618 125.194 410.286 123.531 410.003C122.013 409.745 120.454 409.609 118.865 409.609C117.275 409.609 115.717 409.745 114.198 410.003C112.535 410.286 111.218 411.615 110.96 413.284C110.648 415.321 109.458 417.2 107.531 418.313C105.61 419.421 103.398 419.515 101.482 418.775C99.887 418.16 98.0595 418.67 96.9715 419.993C95.0363 422.354 93.478 425.031 92.3815 427.941C91.7525 429.604 92.3475 431.432 93.7047 432.579C95.229 433.871 96.198 435.786 96.198 437.943C96.198 440.099 95.229 442.014 93.7047 443.306C92.3475 444.456 91.7525 446.281 92.3815 447.947C93.478 450.854 95.0363 453.534 96.9743 455.895C98.0595 457.218 99.887 457.728 101.485 457.113C103.398 456.371 105.61 456.464 107.531 457.572C109.458 458.685 110.648 460.564 110.96 462.601C111.215 464.267 112.535 465.599 114.198 465.882C115.717 466.14 117.275 466.276 118.865 466.276C119.822 466.276 120.766 466.211 121.698 466.114V448.876Z" fill={GERY_DARK}/>
                    </g>
                    <path d="M384.771 30.4164C377.695 30.4164 371.958 36.153 371.958 43.2289C371.958 50.3049 377.695 56.0414 384.771 56.0414C391.847 56.0414 397.584 50.3049 397.584 43.2289C397.584 36.153 391.847 30.4164 384.771 30.4164ZM389.042 42.3748C387.154 42.3748 385.625 40.8458 385.625 38.9581C385.625 37.0704 387.154 35.5414 389.042 35.5414C390.93 35.5414 392.458 37.0704 392.458 38.9581C392.458 40.8458 390.93 42.3748 389.042 42.3748Z" fill={GERY_LIGHT_2}/>
                    <path d="M372.961 48.2053L363.917 57.2492C363.596 57.5704 363.417 58.0043 363.417 58.457V62.8748C363.417 63.8178 364.182 64.5831 365.125 64.5831H369.543C369.995 64.5831 370.431 64.4037 370.75 64.0826L372.959 61.8737C373.412 61.421 373.667 60.806 373.667 60.1654C373.667 58.8312 374.748 57.7498 376.082 57.7498C376.723 57.7498 377.338 57.4952 377.79 57.0425L379.794 55.0387C376.719 53.742 374.258 51.2786 372.961 48.2053Z" fill={GERY_DARK}/>
                    <path d="M166.211 145.5C166.211 156.132 157.537 164.75 146.836 164.75H88.7107C78.0092 164.75 69.3357 156.132 69.3357 145.5V68.5C69.3357 57.8676 78.0092 49.25 88.7107 49.25H146.836C157.537 49.25 166.211 57.8676 166.211 68.5V145.5Z" fill={GERY_LIGHT_2}/>
                    <path d="M127.461 145.5V139.083C127.461 131.993 121.68 126.25 114.544 126.25H62.8774C55.7409 126.25 49.9607 131.993 49.9607 139.083V145.5C49.9607 156.132 58.6342 164.75 69.3357 164.75H146.836C136.134 164.75 127.461 156.132 127.461 145.5Z" fill={GERY_DARK}/>
                    <path d="M140.377 87.75H121.002C117.431 87.75 114.544 84.8754 114.544 81.3334C114.544 77.7914 117.431 74.9167 121.002 74.9167H140.377C143.949 74.9167 146.836 77.7914 146.836 81.3334C146.836 84.8754 143.949 87.75 140.377 87.75Z" fill={GERY_DARK}/>
                    <path d="M140.377 113.417H121.002C117.431 113.417 114.544 110.542 114.544 107C114.544 103.458 117.431 100.583 121.002 100.583H140.377C143.949 100.583 146.836 103.458 146.836 107C146.836 110.542 143.949 113.417 140.377 113.417Z" fill={GERY_DARK}/>
                    <path d="M95.169 87.75C98.7359 87.75 101.627 84.8772 101.627 81.3334C101.627 77.7895 98.7359 74.9167 95.169 74.9167C91.6022 74.9167 88.7107 77.7895 88.7107 81.3334C88.7107 84.8772 91.6022 87.75 95.169 87.75Z" fill={GERY_DARK}/>
                    <path d="M95.169 113.417C98.7359 113.417 101.627 110.544 101.627 107C101.627 103.456 98.7359 100.583 95.169 100.583C91.6022 100.583 88.7107 103.456 88.7107 107C88.7107 110.544 91.6022 113.417 95.169 113.417Z" fill={GERY_DARK}/>
                    <defs>
                         <filter id="filter0_dd_114_786" x="387.059" y="112" width="164.435" height="164.435" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                              <feOffset dx="1" dy="-1"/>
                              <feGaussianBlur stdDeviation="1"/>
                              <feColorMatrix type="matrix" values="0 0 0 0 0.421039 0 0 0 0 0.421039 0 0 0 0 0.421039 0 0 0 0.2 0"/>
                              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_114_786"/>
                              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                              <feOffset dx="-1" dy="1"/>
                              <feGaussianBlur stdDeviation="1"/>
                              <feColorMatrix type="matrix" values="0 0 0 0 0.354372 0 0 0 0 0.354372 0 0 0 0 0.354372 0 0 0 0.2 0"/>
                              <feBlend mode="normal" in2="effect1_dropShadow_114_786" result="effect2_dropShadow_114_786"/>
                              <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_114_786" result="shape"/>
                         </filter>
                         <filter id="filter1_dd_114_786" x="139.701" y="114.495" width="267.441" height="574.806" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                              <feOffset dx="1" dy="-1"/>
                              <feGaussianBlur stdDeviation="1"/>
                              <feColorMatrix type="matrix" values="0 0 0 0 0.421039 0 0 0 0 0.421039 0 0 0 0 0.421039 0 0 0 0.2 0"/>
                              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_114_786"/>
                              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                              <feOffset dx="-1" dy="1"/>
                              <feGaussianBlur stdDeviation="1"/>
                              <feColorMatrix type="matrix" values="0 0 0 0 0.354372 0 0 0 0 0.354372 0 0 0 0 0.354372 0 0 0 0.2 0"/>
                              <feBlend mode="normal" in2="effect1_dropShadow_114_786" result="effect2_dropShadow_114_786"/>
                              <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_114_786" result="shape"/>
                         </filter>
                         <filter id="filter2_dd_114_786" x="0.0439453" y="0" width="223" height="223" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                              <feOffset dx="1" dy="-1"/>
                              <feGaussianBlur stdDeviation="1"/>
                              <feColorMatrix type="matrix" values="0 0 0 0 0.421039 0 0 0 0 0.421039 0 0 0 0 0.421039 0 0 0 0.2 0"/>
                              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_114_786"/>
                              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                              <feOffset dx="-1" dy="1"/>
                              <feGaussianBlur stdDeviation="1"/>
                              <feColorMatrix type="matrix" values="0 0 0 0 0.354372 0 0 0 0 0.354372 0 0 0 0 0.354372 0 0 0 0.2 0"/>
                              <feBlend mode="normal" in2="effect1_dropShadow_114_786" result="effect2_dropShadow_114_786"/>
                              <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_114_786" result="shape"/>
                         </filter>
                         <filter id="filter3_dd_114_786" x="333.773" y="5.99976" width="89.3999" height="89.4" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                              <feOffset dx="1" dy="-1"/>
                              <feGaussianBlur stdDeviation="1"/>
                              <feColorMatrix type="matrix" values="0 0 0 0 0.421039 0 0 0 0 0.421039 0 0 0 0 0.421039 0 0 0 0.2 0"/>
                              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_114_786"/>
                              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                              <feOffset dx="-1" dy="1"/>
                              <feGaussianBlur stdDeviation="1"/>
                              <feColorMatrix type="matrix" values="0 0 0 0 0.354372 0 0 0 0 0.354372 0 0 0 0 0.354372 0 0 0 0.2 0"/>
                              <feBlend mode="normal" in2="effect1_dropShadow_114_786" result="effect2_dropShadow_114_786"/>
                              <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_114_786" result="shape"/>
                         </filter>
                         <filter id="filter4_dd_114_786" x="431.424" y="333.457" width="71.8682" height="71.868" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                              <feOffset dx="1" dy="-1"/>
                              <feGaussianBlur stdDeviation="1"/>
                              <feColorMatrix type="matrix" values="0 0 0 0 0.421039 0 0 0 0 0.421039 0 0 0 0 0.421039 0 0 0 0.2 0"/>
                              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_114_786"/>
                              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                              <feOffset dx="-1" dy="1"/>
                              <feGaussianBlur stdDeviation="1"/>
                              <feColorMatrix type="matrix" values="0 0 0 0 0.354372 0 0 0 0 0.354372 0 0 0 0 0.354372 0 0 0 0.2 0"/>
                              <feBlend mode="normal" in2="effect1_dropShadow_114_786" result="effect2_dropShadow_114_786"/>
                              <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_114_786" result="shape"/>
                         </filter>
                         <filter id="filter5_dd_114_786" x="60.1001" y="377.282" width="122.8" height="122.8" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                              <feOffset dx="1" dy="-1"/>
                              <feGaussianBlur stdDeviation="1"/>
                              <feColorMatrix type="matrix" values="0 0 0 0 0.421039 0 0 0 0 0.421039 0 0 0 0 0.421039 0 0 0 0.2 0"/>
                              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_114_786"/>
                              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                              <feOffset dx="-1" dy="1"/>
                              <feGaussianBlur stdDeviation="1"/>
                              <feColorMatrix type="matrix" values="0 0 0 0 0.354372 0 0 0 0 0.354372 0 0 0 0 0.354372 0 0 0 0.2 0"/>
                              <feBlend mode="normal" in2="effect1_dropShadow_114_786" result="effect2_dropShadow_114_786"/>
                              <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_114_786" result="shape"/>
                         </filter>
                         <clipPath id="clip0_114_786">
                              <rect width="68" height="68" fill={GERY_LIGHT_2} transform="translate(84.8647 403.943)"/>
                         </clipPath>
                    </defs>
               </svg>

          </Box>
     );
}
