import PropTypes from "prop-types";
import { useState } from "react";
import { Icon } from "@iconify/react";
import { matchPath , NavLink as RouterLink , useLocation } from "react-router-dom";
import arrowIosBackFill from "@iconify/icons-eva/arrow-ios-back-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import { alpha , styled , useTheme } from "@mui/material/styles";
import {
  Box ,
  Collapse ,
  List ,
  ListItemButton ,
  ListItemIcon ,
  ListItemText ,
  ListSubheader
} from "@mui/material";
import { useSelector } from "../redux/store";
import InstallPWA from "../utils/installpwa";

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    color: theme.palette.text.primary
  })
);

const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main
  }
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  isShow: PropTypes.bool,
  item: PropTypes.object
};




function NavItem({ item, isShow }) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { title, path, icon, info, children,showKey } = item;
  const isActiveRoot = path ? !!matchPath({ path, end: false }, pathname) : false;
  const { currentAdmin } = useSelector( ( state ) => state.admin );

  const [open, setOpen] = useState(isActiveRoot);
  const showParent=children?.filter( ( child ) => (child.key === 'superAdmin') === currentAdmin.isSuperAdmin)
 
  // console.log(currentAdmin.isSuperAdmin === (showKey === 'superAdmin'))
  const handleOpen = () => {
    setOpen(!open);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium'
  };
  if (children) {
    return (
      <>
        {
          currentAdmin.isSuperAdmin ?
               <>
                 <ListItemStyle
                      onClick={handleOpen}
                      sx={{
                        ...(isActiveRoot && activeRootStyle)
                      }}
                 >
                   <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
         
                   {isShow &&  (
                        <>
                
                          <ListItemText disableTypography primary={title} />
                          {info && info}
                          <Box
                               component={Icon}
                               icon={open ? arrowIosDownwardFill : arrowIosBackFill}
                               sx={{ width: 16, height: 16, ml: 1 }}
                          />
                        </>
                   )}
                 </ListItemStyle>
       
                 {isShow && (
                      <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {children.map((item) => {
                            const { title, path,key } = item;
                            const isActiveSub = path ? !!matchPath({ path, end: false }, pathname) : false;
                  
                  
                            return (
                                 <>
                                   {
                                     currentAdmin.isSuperAdmin ?
                                          <ListItemStyle
                                               key={title}
                                               component={RouterLink}
                                               to={path}
                                               sx={{
                                                 ...(isActiveSub && activeSubStyle)
                                               }}
                                          >
                                            <ListItemIconStyle>
                                              <Box
                                                   component="span"
                                                   sx={{
                                                     width: 4,
                                                     height: 4,
                                                     display: 'flex',
                                                     borderRadius: '50%',
                                                     alignItems: 'center',
                                                     justifyContent: 'center',
                                                     bgcolor: 'text.disabled',
                                                     transition: (theme) => theme.transitions.create('transform'),
                                                     ...(isActiveSub && {
                                                       transform: 'scale(2)',
                                                       bgcolor: 'primary.main'
                                                     })
                                                   }}
                                              />
                                            </ListItemIconStyle>
                                            <ListItemText disableTypography primary={title} />
                                          </ListItemStyle>
                                
                                
                                
                                          : currentAdmin.isSuperAdmin === (key === 'superAdmin') &&
                                          <ListItemStyle
                                               key={title}
                                               component={RouterLink}
                                               to={path}
                                               sx={{
                                                 ...(isActiveSub && activeSubStyle)
                                               }}
                                          >
                                            <ListItemIconStyle>
                                              <Box
                                                   component="span"
                                                   sx={{
                                                     width: 4,
                                                     height: 4,
                                                     display: 'flex',
                                                     borderRadius: '50%',
                                                     alignItems: 'center',
                                                     justifyContent: 'center',
                                                     bgcolor: 'text.disabled',
                                                     transition: (theme) => theme.transitions.create('transform'),
                                                     ...(isActiveSub && {
                                                       transform: 'scale(2)',
                                                       bgcolor: 'primary.main'
                                                     })
                                                   }}
                                              />
                                            </ListItemIconStyle>
                                            <ListItemText disableTypography primary={title} />
                                          </ListItemStyle>
                           
                                   }
                       
                                 </>
                            );
                          })}
                        </List>
                      </Collapse>
                 )}
               </>
     
               : showParent.length !== 0  &&
             
             <>
               <ListItemStyle
                    onClick={handleOpen}
                    sx={{
                      ...(isActiveRoot && activeRootStyle)
                    }}
               >
                 <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
    
                 {isShow &&  (
                      <>
           
                        <ListItemText disableTypography primary={title} />
                        {info && info}
                        <Box
                             component={Icon}
                             icon={open ? arrowIosDownwardFill : arrowIosBackFill}
                             sx={{ width: 16, height: 16, ml: 1 }}
                        />
                      </>
                 )}
               </ListItemStyle>
  
               {isShow && (
                    <Collapse in={open} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {children.map((item) => {
                          const { title, path,key } = item;
                          const isActiveSub = path ? !!matchPath({ path, end: false }, pathname) : false;
             
             
                          return (
                               <>
                                 {
                                   currentAdmin.isSuperAdmin ?
                                        <ListItemStyle
                                             key={title}
                                             component={RouterLink}
                                             to={path}
                                             sx={{
                                               ...(isActiveSub && activeSubStyle)
                                             }}
                                        >
                                          <ListItemIconStyle>
                                            <Box
                                                 component="span"
                                                 sx={{
                                                   width: 4,
                                                   height: 4,
                                                   display: 'flex',
                                                   borderRadius: '50%',
                                                   alignItems: 'center',
                                                   justifyContent: 'center',
                                                   bgcolor: 'text.disabled',
                                                   transition: (theme) => theme.transitions.create('transform'),
                                                   ...(isActiveSub && {
                                                     transform: 'scale(2)',
                                                     bgcolor: 'primary.main'
                                                   })
                                                 }}
                                            />
                                          </ListItemIconStyle>
                                          <ListItemText disableTypography primary={title} />
                                        </ListItemStyle>
     
     
     
                                        : currentAdmin.isSuperAdmin === (key === 'superAdmin') &&
                                      <ListItemStyle
                                           key={title}
                                           component={RouterLink}
                                           to={path}
                                           sx={{
                                             ...(isActiveSub && activeSubStyle)
                                           }}
                                      >
                                        <ListItemIconStyle>
                                          <Box
                                               component="span"
                                               sx={{
                                                 width: 4,
                                                 height: 4,
                                                 display: 'flex',
                                                 borderRadius: '50%',
                                                 alignItems: 'center',
                                                 justifyContent: 'center',
                                                 bgcolor: 'text.disabled',
                                                 transition: (theme) => theme.transitions.create('transform'),
                                                 ...(isActiveSub && {
                                                   transform: 'scale(2)',
                                                   bgcolor: 'primary.main'
                                                 })
                                               }}
                                          />
                                        </ListItemIconStyle>
                                        <ListItemText disableTypography primary={title} />
                                      </ListItemStyle>
                      
                                 }
                  
                               </>
                          );
                        })}
                      </List>
                    </Collapse>
               )}
             </>
        }
        
       
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle)
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      {isShow && (
        <>
          <ListItemText disableTypography primary={title} />
          {info && info}
        </>
      )}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  isShow: PropTypes.bool,
  navConfig: PropTypes.array
};

export default function NavSection({ navConfig, isShow = true, ...other }) {
  return (
    <Box {...other}>
      {navConfig.map((list) => {
        const { subheader, items } = list;
        return (
          <List key={subheader} disablePadding>
            {isShow && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>}
            {items.map((item) => (
              <NavItem  key={item.title} item={item} isShow={isShow} />
            )
            )}
          </List>
        );
      })}
     
    </Box>
  );
}
