import * as Yup from "yup";
import PropTypes from "prop-types";
import React , { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Form , FormikProvider , useFormik } from "formik";
// material
import { LoadingButton } from "@mui/lab";
import {
     Autocomplete ,
     Box ,
     Button ,
     Card ,
     Checkbox , FormControlLabel ,
     Grid ,
     Stack , Switch ,
     TextField ,
     Tooltip
} from "@mui/material";
import { PATH_DASHBOARD } from "../../../../routes/paths";
import axios from "../../../../utils/axiosinstance";
import { fileBuffer } from "../../../../utils/formatNumber";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch , useSelector } from "../../../../redux/store";
import {
     getAttributes ,
     getFactories , getProducts ,
     getSubCategory
} from "../../../../redux/slices/product";
// utils
// routes


// ----------------------------------------------------------------------

DiscountNewForm.propTypes = {
     isEdit            : PropTypes.bool ,
     currentDiscount : PropTypes.object
};


export default function DiscountNewForm( { isEdit , currentDiscount } ) {
     const navigate = useNavigate();
     const { enqueueSnackbar } = useSnackbar();
     const [ state , setState ] = React.useState( "" )
     const [ stateEn , setStateEn ] = React.useState( "" )
     const [ faValues , setFaValues ] = React.useState( currentDiscount?.values || [] )
     const dispatch = useDispatch();
     const [ properValuesFa , setProperValuesFa ] = React.useState( currentDiscount?.values || [] )
     const {
                isLoading ,productsList:{ products},
                subCategoriesList : { subcategories : categories } ,
           } = useSelector( ( state ) => state.product )
     useEffect(() => {
          if (!products?.length) {
               dispatch( getProducts() );
          }
     }, [dispatch, products?.length]);
     
     const NewUserSchema = Yup.object().shape( {
          body : Yup.string().required( "توضیحات الزامی است" ) ,
          discountStatus : Yup.string().required( "وضعیت تخفیف الزامی است" ) ,
          percentage        : Yup.string().required( "درصد الزامی است" ) || 0,
          
     } );
     // let f=properValuesEn
     
     const formik = useFormik( {
          enableReinitialize : true ,
          initialValues      : {
               title   : currentDiscount?.title || "" ,
               body   : currentDiscount?.body || "" ,
               discountStatus   : currentDiscount?.discountStatus || false ,
               percentage   : currentDiscount?.percentage || 0 ,
               products    : currentDiscount?.products?.name  ,
               
          } ,
          validationSchema   : NewUserSchema ,
          onSubmit           : async ( values , { setSubmitting , resetForm , setErrors } ) => {
               const { title ,subcategory,body  ,discountStatus,percentage} = values
               const productId = products.find( c => c.name === subcategory )?._id || null;
               
               try {
                    if ( !isEdit ) {
                         const res = await axios.post( `discount` , {
                              title:"title" ,percentage:percentage.toString(),discountStatus:discountStatus ? "1":"0"
                              ,product:productId,body:body
                              }
                         )
                         if ( res.status === 200 ) {
                              enqueueSnackbar( "ساخت با موفقیت انجام شد. " , { variant : "success" } );
                              navigate( PATH_DASHBOARD.eCommerce.discountsList );
                              resetForm();
                              setSubmitting( false );
                         }
                       
                    } else {
                         const res = await axios.patch( `discount/${currentDiscount._id}` , {
                              updates:{
                                   title:"title" ,percentage:percentage.toString(),discountStatus:discountStatus ? "1":"0"
                                   ,product:productId,body:body
                                   
                              }
                              }
                           
                         )
                         if ( res.status === 200 ) {
                              enqueueSnackbar( "آپدیت با موفقیت انجام شد. " , { variant : "success" } );
                              navigate( PATH_DASHBOARD.eCommerce.discountsList );
                              resetForm();
                              setSubmitting( false );
                         }
                    }
                    
                    
                    setSubmitting( false );
               } catch (error) {
                    enqueueSnackbar( error , { variant : "error" } );
                    setSubmitting( false );
                    setErrors( error );
               }
          }
     } );
     
     const {
                errors ,
                values ,
                touched ,
                handleSubmit ,
                isSubmitting ,
                setFieldValue ,
                getFieldProps
           } = formik;
     
     
     return (
          <FormikProvider value = { formik } >
               <Form noValidate autoComplete onSubmit = { handleSubmit } >
                    <Grid container spacing = { 3 } alignItems = "center" justifyContent = "center" >
                         <Grid item xs = { 12 } md = { 12 } >
                              <Card sx = { { p : 3 } } >
                                   <Stack spacing = { 3 } >
                                        <Grid item xs = { 2 } md = { 2 } >
                                             
                                             <FormControlLabel
                                                  control = {
                                                       <Switch { ...getFieldProps( "discountStatus" ) }
                                                            checked = { values.discountStatus } /> }
                                                  label = "وضعیت تخفیف"
                                                  labelPlacement = "start"
                                                  sx = { {
                                                       width          : "100%" ,
                                                       justifyContent : "space-between"
                                                  } }
                                             />
                                         
                                        </Grid>
                                        <Stack direction = { { xs : "column" , sm : "row" } }
                                             spacing = { { xs : 3 , sm : 2 } } >
                                         {/*
                                             <TextField
                                                  fullWidth
                                                  label = "عنوان  "
                                                  { ...getFieldProps( "title" ) }
                                                  error = { Boolean( touched.title && errors.title ) }
                                                  helperText = { touched.title && errors.title }
                                             />*/}
                                             <Autocomplete
                                                  fullWidth
                                                  freeSolo
                                                  disableClearable
                                                  value = { values.subcategory }
                                                  onChange = { ( event , newValue ) => {
                                                       setFieldValue( "subcategory" , newValue )
                                                  } }
                                                  noOptionsText = { "گزینه دیگری وجود ندارد" }
                                                  options = { products?.map( item => item.name ) }
                                                  renderInput = { ( params ) => (
                                                       <TextField { ...params }
                                                            label = "محصولات"
                                                            InputProps = { {
                                                                 ...params.InputProps ,
                                                                 type : "search"
                                                            } } />
                                                  ) }
                                             />
                                        
                                        </Stack >
                                        <Stack direction = { { xs : "column" , sm : "row" } }
                                             spacing = { { xs : 3 , sm : 2 } } >
                                             
                                             <TextField
                                                  fullWidth
                                                  multiline
                                                  minRows = { 3 }
                                                  label = "توضیحات"
                                                  { ...getFieldProps( "body" ) }
                                                  error = { Boolean( touched.body && errors.body ) }
                                                  helperText = { touched.body && errors.fa_body }
                                             />
                                        </Stack >
                                        <Stack direction = { { xs : "column" , sm : "row" } }
                                             spacing = { { xs : 3 , sm : 2 } } >
                                                  <TextField
                                                       fullWidth
                                                       InputProps = { {
                                                            startAdornment : <InputAdornment
                                                                 position = "start" >%</InputAdornment > ,
                                                            type           : "number"
                                                       } }
                                                       label = " درصد"
                                                       { ...getFieldProps( "percentage" ) }
                                                       error = { Boolean( touched.percentage && errors.percentage ) }
                                                       helperText = { touched.percentage && errors.percentage }
                                                  />
                                           
                                          
                                             
                                        </Stack >
                                        <Box sx = { {
                                             mt      : 3 ,
                                             display : "flex" ,
                                        } } >
                                             <LoadingButton type = "submit"
                                                  variant = "contained"
                                                  loading = { isSubmitting } >
                                                  { !isEdit ? "ساخت ویژگی جدید" : "ذخیره تغییرات" }
                                             </LoadingButton >
                                        </Box >
                                   </Stack >
                              </Card >
                         </Grid >
                    </Grid >
               </Form >
          </FormikProvider >
     );
}
