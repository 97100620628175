import { createSlice } from "@reduxjs/toolkit";
// utils
import { default as ax } from "../../utils/axiosinstance";

// ----------------------------------------------------------------------

const initialState = {
     isLoading: false,
     error: false,
     isOpenModal      : false ,
     expertsList: [],
     currentExpert: {},
};

const slice = createSlice({
     name: 'expert',
     initialState,
     reducers: {
          // START LOADING
          startLoading(state) {
               state.isLoading = true;
          },
          
          // HAS ERROR
          hasError(state, action) {
               state.isLoading = false;
               state.error = action.payload;
          },
          
          
          // GET EXPERTS
          getExpertListSuccess(state, action) {
               state.isLoading = false;
               state.expertsList = action.payload;
          },
          // GET EXPERT
          getCurrentExpertSuccess(state, action) {
               state.isLoading = false;
               state.currentExpert = action.payload;
          },
     
          // OPEN MODAL
          openModal( state ) {
               state.isOpenModal = true;
          } ,
     
          // CLOSE MODAL
          closeModal( state ) {
               state.isOpenModal = false;
               state.selectedEventId = null;
               state.selectedRange = null;
          } ,
     }
});

// Reducer
export default slice.reducer;

// Actions
export const {   openModal , closeModal} = slice.actions;




// ----------------------------------------------------------------------

export function getExpertList( limit=1000,page=0, sortBy='name',sortOrder='asc',search='') {
     return async (dispatch) => {
          dispatch(slice.actions.startLoading());
          try {
               const response = await ax.get(`site-info/contact-us/expert?limit=${limit}&skip=${page}&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${search}`);
               dispatch(slice.actions.getExpertListSuccess(response.data));
          } catch (error) {
               dispatch(slice.actions.hasError(error));
          }
     };
}
// ----------------------------------------------------------------------

export function getCurrentExpert( id) {
     return async (dispatch) => {
          dispatch(slice.actions.startLoading());
          try {
               const response = await ax.get(`site-info/contact-us/expert/${id}`);
               dispatch(slice.actions.getCurrentExpertSuccess(response.data));
          } catch (error) {
               dispatch(slice.actions.hasError(error));
          }
     };
}
export function deleteExpert( id) {
     return async (dispatch) => {
          dispatch(slice.actions.startLoading());
          try {
               const response = await ax.delete(`authorities/${id}`);
               dispatch(getExpertList())
          } catch (error) {
               dispatch(slice.actions.hasError(error));
          }
     };
}
