// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// components
import SvgIconStyle from "../../components/SvgIconStyle";

// ----------------------------------------------------------------------

const getIcon = ( name ) => (
     <SvgIconStyle src = { `/static/icons/navbar/${ name }.svg` }
          sx = { { width : "100%" , height : "100%" } } />
);

const ICONS = {
     blog   : getIcon( "ic_blog" ) ,
     shop   : getIcon( "ic_shop" ) ,
     chat   : getIcon( "ic_chat" ) ,
     mail   : getIcon( "ic_mail" ) ,
     user   : getIcon( "ic_user" ) ,
     page   : getIcon( "ic_page" ) ,
     contact   : getIcon( "ic_contact" ) ,
     about   : getIcon( "ic_about" ) ,
     report : getIcon( "ic_report" ) ,
     faq    : getIcon( "ic_faq" ) ,
     expert : getIcon( "ic_communicate" ) ,
     admin     : getIcon( "ic_admin" ) ,
     property  : getIcon( "ic_edit_property" ) ,
     order     : getIcon( "ic_purchase_order" ) ,
     analytics : getIcon( "ic_analytics" ) ,
     seo : getIcon( "ic_increase" ) ,
     dashboard : getIcon( "ic_dashboard" )
};

const sidebarConfig = [
     // GENERAL
     // ----------------------------------------------------------------------
     {
          subheader : "عمومی" ,
          items     : [
               {
               title : "آمار سایت" ,
               path  : PATH_DASHBOARD.general.ecommerce ,
               icon  : ICONS.analytics
          } ,
             /*  {
                    title    : " سئو" ,
                    path     : PATH_DASHBOARD.seo.root ,
                    icon     : ICONS.seo ,
               } ,*/
                        {
                             title    : "ادمین ها" ,
                             path     : PATH_DASHBOARD.admin.root ,
                             icon     : ICONS.admin ,
                             children : [
                                  { title : "لیست ادمین ها" , path : PATH_DASHBOARD.admin.list,key:"superAdmin" } ,
                                  { title : "افزودن ادمین" , path : PATH_DASHBOARD.admin.newAdmin ,key:"superAdmin"}
                             ]
                        } ,   {
                             title    : "مشتری ها" ,
                             path     : PATH_DASHBOARD.admin.root ,
                             icon     : ICONS.user ,
                             children : [
                                  { title : "لیست مشتری ها" , path : PATH_DASHBOARD.user.list ,key:"superAdmin"} ,
                                  { title : "افزودن مشتری" , path : PATH_DASHBOARD.user.newUser,key:"superAdmin" }
                             ]
                        } ,
          
          ]
     } ,
     // MANAGEMENT : ADMIN
     
     
     // MANAGEMENT
     // ----------------------------------------------------------------------
     {
          subheader : "مدیریت" ,
          items     : [
               {
                    title    : " صفحه اصلی" ,
                    path     : PATH_DASHBOARD.siteData.root ,
                    icon     : ICONS.property ,
                    children : [
                         { title : "بارگذاری لوگو" , path : PATH_DASHBOARD.siteData.uploadLogo,key:"superAdmin" } ,
                         { title : "اخبار روز و بنر هدر" , path : PATH_DASHBOARD.siteData.newsAndBanner ,key:"superAdmin"} ,
                         { title : "شبکه های اجتماعی" , path : PATH_DASHBOARD.siteData.socialMedia ,key:"superAdmin"} ,
                         { title : "مراحل ثبت سفارش" , path : PATH_DASHBOARD.siteData.ordersLevel ,key:"superAdmin"} ,
                         { title : "محصولات ویژه نمایش در هدر" , path : PATH_DASHBOARD.siteData.specialProducts,key:"superAdmin" } ,
                         { title : "بنرها" , path : PATH_DASHBOARD.siteData.banners ,key:"superAdmin"} ,
                         { title : "فوتر" , path : PATH_DASHBOARD.siteData.footer ,key:"superAdmin"} ,
                    ]
               } ,
               // MANAGEMENT : About Us
               {
                    title    : " درباره ما" ,
                    path     : PATH_DASHBOARD.siteData.root ,
                    icon     : ICONS.about ,
                    children : [
                         { title : "محتوای صفحه" , path : PATH_DASHBOARD.siteData.aboutUs ,key:"superAdmin"} ,
                         { title : "لیست پروژه ها" , path : PATH_DASHBOARD.projects.projectsLists,key:"superAdmin" } ,
                         { title : "افزودن پروژه" , path : PATH_DASHBOARD.projects.newProjects,key:"superAdmin" } ,
                    ]
               } ,
               // MANAGEMENT : Contact Us
               {
                    title    : " تماس با ما" ,
                    path     : PATH_DASHBOARD.siteData.root ,
                    icon     : ICONS.contact ,
                    children : [
                         { title : "محتوای صفحه" , path : PATH_DASHBOARD.siteData.contactUs,key:"superAdmin" } ,
                    { title : "لیست سوال و جواب ها" , path : PATH_DASHBOARD.faq.list ,key:"superAdmin"} ,
                         { title : "افزودن سوال و جواب " , path : PATH_DASHBOARD.faq.newFaq ,key:"superAdmin"} ,
                         { title : "لیست کارشناس های فروش " , path : PATH_DASHBOARD.expert.list,key:"superAdmin" } ,
                         { title : "افزودن کارشناس فروش" , path : PATH_DASHBOARD.expert.newExpert ,key:"superAdmin"} ,

                    ]
               } ,
               // MANAGEMENT : E-COMMERCE
               
               {
                    title    : "فروشگاه" ,
                    path     : PATH_DASHBOARD.eCommerce.root ,
                    icon     : ICONS.shop ,
                    children : [
                         { title : "لیست محصولات" , path : PATH_DASHBOARD.eCommerce.list } ,
                         { title : "محصولات تخفیف دار" , path : PATH_DASHBOARD.eCommerce.discountsList } ,
                         { title : "افزودن محصول" , path : PATH_DASHBOARD.eCommerce.newProduct } ,
                         { title : "لیست دسته بندی ها " , path : PATH_DASHBOARD.eCommerce.categoriesList ,key:"superAdmin"} ,
                         { title : "لیست زیر دسته ها " , path : PATH_DASHBOARD.eCommerce.subCategoriesList ,key:"superAdmin"} ,
                         { title : " افزودن دسته بندی" , path : PATH_DASHBOARD.eCommerce.categories ,key:"superAdmin"} ,
                         { title : "لیست ویژگی ها" , path : PATH_DASHBOARD.eCommerce.attributesList ,key:"superAdmin"} ,
                         { title : "افزودن ویژگی " , path : PATH_DASHBOARD.eCommerce.attributes,key:"superAdmin" } ,
                         { title : "لیست  کارخانجات" , path : PATH_DASHBOARD.eCommerce.factoriesList ,key:"superAdmin"} ,
                         { title : "افزودن کارخونه " , path : PATH_DASHBOARD.eCommerce.factories ,key:"superAdmin"} ,
                    
                    ]
               } ,
               
          
               
               //       // MANAGEMENT : ORDER
               {
                    title    : "سفارشات" ,
                    path     : PATH_DASHBOARD.order.root ,
                    icon     : ICONS.order ,
                    children : [
                         { title : "لیست سفارش ها" , path : PATH_DASHBOARD.order.list } ,
                         // { title : " سفارشات تلفنی" , path : PATH_DASHBOARD.order.phoneList } ,
                    ]
               } ,

               
               // MANAGEMENT : USER
               // {
               //      title    : "کاربر" ,
               //      path     : PATH_DASHBOARD.user.root ,
               //      icon     : ICONS.user ,
               //      children : [
               //           // { title : "لیست" , path : PATH_DASHBOARD.user.list } ,
               //           { title : "کامنت ها محصولات" , path : PATH_DASHBOARD.user.comments } ,
               //           { title : "کامنت ها بلاگ" , path : PATH_DASHBOARD.user.blogComments } ,
               //
               //      ]
               // } ,
               
               // MANAGEMENT : BLOG
               {
                    title    : "بلاگ" ,
                    path     : PATH_DASHBOARD.blog.root ,
                    icon     : ICONS.blog ,
                    children : [
                         { title : "لیست پست ها" , path : PATH_DASHBOARD.blog.posts,key:"superAdmin" } ,
                         { title : "پست جدید" , path : PATH_DASHBOARD.blog.newPost,key:"superAdmin" } ,
                         // { title : "دسته بندی" , path : PATH_DASHBOARD.blog.categories } ,

                    ]
               } ,

               
               
               // MANAGEMENT : REPORTS
               {
                    title    : "گزارشات" ,
                    path     : PATH_DASHBOARD.order.root ,
                    icon     : ICONS.report ,
                    children : [
                         { title : "لیست گزارش ها" , path : PATH_DASHBOARD.report.list ,key:"superAdmin"} ,
                    ]
               },
               {
                    title    : " صفحه ساز" ,
                    path     : PATH_DASHBOARD.siteData.root ,
                    icon     : ICONS.page ,
                    children : [
                         { title : "لیست صفحه ها" , path : PATH_DASHBOARD.page.list ,key:"superAdmin"} ,
                         { title : "صفحه جدید" , path : PATH_DASHBOARD.page.newPage,key:"superAdmin" } ,
                    ]
               } ,
            
          
          ]
     }
];

export default sidebarConfig;
