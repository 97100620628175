import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
// material
import { styled , useTheme } from "@mui/material/styles";
import {
     Box ,
     IconButton ,
     InputAdornment ,
     OutlinedInput ,
     Toolbar ,
     Tooltip ,
     Autocomplete ,
     Typography , TextField , Checkbox
} from "@mui/material";
import React from "react";

// ----------------------------------------------------------------------

ListToolbar.propTypes = {
     numSelected  : PropTypes.number ,
     filterName   : PropTypes.string ,
     onFilterName : PropTypes.func
};
const RootStyle = styled( Toolbar )( ( { theme } ) => ({
     height         : 96 ,
     width         : '100%' ,
     display        : "flex" ,
     justifyContent : "space-between" ,
     gap : "16px" ,
     padding        : theme.spacing( 0 , 1 , 0 , 3 )
}) );

const SearchStyle = styled( OutlinedInput )( ( { theme } ) => ({
     width           : 240 ,
     transition      : theme.transitions.create( [ "box-shadow" , "width" ] , {
          easing   : theme.transitions.easing.easeInOut ,
          duration : theme.transitions.duration.shorter
     } ) ,
     "&.Mui-focused" : { width : 320 , boxShadow : theme.customShadows.z8 } ,
     "& fieldset"    : {
          borderWidth : `1px !important` ,
          borderColor : `${ theme.palette.grey[ 500_32 ] } !important`
     }
}) );

// ----------------------------------------------------------------------


const BoxWrapper = styled( Box )`
     border-radius : 50%;
     background    : white;
`;
const CodeStyle = styled(Autocomplete)(({ theme }) => ({
     width: '20%',
     transition: theme.transitions.create(['box-shadow', 'width'], {
          easing: theme.transitions.easing.easeInOut,
          duration: theme.transitions.duration.shorter
     }),
     '&.Mui-focused': { width: '50%', boxShadow: theme.customShadows.z8 },
     '& fieldset': {
          borderWidth: `1px !important`,
          borderColor: `${theme.palette.grey[500_32]} !important`
     }
}));
export default function ListToolbar( { numSelected , filterName , onFilterName , onDelete ,subcategories
,setSubcategorySelect,subcategorySelect,hasFilter=false,factories,factorySelect,setFactorySelect} ) {
     const theme = useTheme();
     const isLight = theme.palette.mode === "light";
     
     return (
          <RootStyle
               sx = { {
                    ...(numSelected > 0 && {
                         color   : isLight ? "primary.main" : "text.primary" ,
                         bgcolor : isLight ? "primary.lighter" : "primary.dark"
                    })
               } }
          >
               { numSelected > 0 ? (
                    <Typography component = "div" variant = "subtitle1" >
                         { numSelected }مورد انتخاب شده است
                    </Typography >
               ) : (
                    <>
                         <SearchStyle
                              value = { filterName }
                              onChange = { onFilterName }
                              placeholder = "جستجو..."
                              startAdornment = {
                                   <InputAdornment position = "start" >
                                        <Box component = { Icon } icon = { searchFill }
                                             sx = { { color : "text.disabled" } } />
                                   </InputAdornment >
                              }
                         />
                    </>
              
               ) }
               
               { numSelected > 0 ? (
                    <BoxWrapper >
                         <Tooltip title = "حذف" >
                              <IconButton onClick = { onDelete } >
                                   <Icon icon = { trash2Fill } />
                              </IconButton >
                         </Tooltip >
                    </BoxWrapper >
               ) : null
                    //   (
                    //   <Tooltip title="لیست فیلترها">
                    //     <IconButton>
                    //       <Icon icon={roundFilterList} />
                    //     </IconButton>
                    //   </Tooltip>
                    // )
               }
          </RootStyle >
     );
}
