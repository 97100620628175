// material
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function PageNotFoundIllustration({ ...other }) {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARKER = theme.palette.primary.darker;

  return (
    <Box {...other}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 480 360">
        <defs>
          <linearGradient id="BG" x1="19.496%" x2="77.479%" y1="71.822%" y2="16.69%">
            <stop offset="0%" stopColor={PRIMARY_MAIN} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} stopOpacity="0" />
          </linearGradient>
          <linearGradient id="linearGradient-2" x1="-50%" x2="50%" y1="50%" y2="150.001%">
            <stop offset="0%" stopColor="#FFE16A" />
            <stop offset="100%" stopColor="#B78103" />
          </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g fillRule="nonzero">

            <path
              fill={PRIMARY_DARKER}
              d="M244.878 181.46c34.559 0 62.575 28.016 62.575 62.576 0 34.559-28.016 62.575-62.575 62.575-34.56 0-62.576-28.016-62.576-62.575 0-34.56 28.016-62.576 62.576-62.576zm0 23.186c-21.754 0-39.389 17.635-39.389 39.39 0 21.754 17.635 39.389 39.389 39.389s39.389-17.635 39.389-39.389c0-21.755-17.635-39.39-39.389-39.39z"
            />
            <path
              fill={PRIMARY_MAIN}
              d="M174.965 264.592c0-4.133-1.492-5.625-5.637-5.625h-11.373v-66.611c0-4.476-1.492-5.637-5.638-5.637h-9.172a9.867 9.867 0 00-7.948 3.974l-55.03 68.274a11.006 11.006 0 00-1.957 6.787v5.968c0 4.145 1.492 5.637 5.625 5.637h54.676v21.707c0 4.133 1.492 5.625 5.625 5.625h8.12c4.146 0 5.638-1.492 5.638-5.625v-21.707h11.434c4.414 0 5.637-1.492 5.637-5.637v-7.13zm-72.42-5.625l35.966-44.415v44.415h-35.966zM411.607 264.592c0-4.133-1.492-5.625-5.638-5.625h-11.421v-66.611c0-4.476-1.492-5.637-5.638-5.637h-9.11a9.869 9.869 0 00-7.949 3.974l-55.03 68.274a10.998 10.998 0 00-1.981 6.787v5.968c0 4.145 1.491 5.637 5.625 5.637h54.688v21.707c0 4.133 1.491 5.625 5.625 5.625h8.12c4.145 0 5.637-1.492 5.637-5.625v-21.707h11.434c4.476 0 5.638-1.492 5.638-5.637v-7.13zm-72.42-5.625l35.966-44.415v44.415h-35.966z"
            />

          </g>
        </g>
      </svg>
    </Box>
  );
}
