import { lazy , Suspense } from "react";
import { Navigate , useLocation , useRoutes } from "react-router-dom";
// layouts
import MainLayout from "../layouts/main";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from "../components/LoadingScreen";
import RoleBasedGuard from "../guards/RoleBasedGuard";
import { useSelector } from "../redux/store";
import EcommerceDiscountList
   from "../pages/dashboard/Ecommerce/EcommerceDiscountList";
import EcommerceDiscountCreate
   from "../pages/dashboard/Ecommerce/EcommerceDiscountCreate";

// ----------------------------------------------------------------------

const Loadable = ( Component ) => ( props ) => {
     // eslint-disable-next-line react-hooks/rules-of-hooks
     const { pathname } = useLocation();
     const isDashboard = pathname.includes( "/dashboard" );
     
     return (
          <Suspense
               fallback = {
                    <LoadingScreen
                         sx = { {
                              ...(!isDashboard && {
                                   top      : 0 ,
                                   left     : 0 ,
                                   width    : 1 ,
                                   zIndex   : 9999 ,
                                   position : "fixed"
                              })
                         } }
                    />
               }
          >
               <Component { ...props } />
          </Suspense >
     );
};


export default function Router() {
     const {isLoading, currentAdmin } = useSelector( ( state ) => state.admin );
     return  useRoutes( [
          {
               path     : "auth" ,
               children : [
                    {
                         path    : "login" ,
                         element : (
                              <GuestGuard >
                                   <Login />
                              </GuestGuard >
                         )
                    } ,
                    { path : "login-unprotected" , element : <Login /> } ,
                    { path : "reset-password" , element : <ResetPassword /> } ,
                    { path : "forgot-password/:token" , element : <ForgotPassword /> } ,
                    { path : "verify" , element : <VerifyCode /> }
               ]
          } ,
          
          // Dashboard Routes
          {
               path     : "dashboard" ,
               element  : (
                    <AuthGuard >
                         <DashboardLayout />
                    </AuthGuard >
               ) ,
               children : [
                    { element : <Navigate to = "/dashboard/ecommerce" replace /> } ,
                    { path : "ecommerce" , element :
                             <GeneralEcommerce /> } ,
                    
                    {
                         path     : "data" ,
                         children : [
                              { element : <Navigate to = "/dashboard/data/logo" replace /> } ,
                              {
                                 ...(currentAdmin.isSuperAdmin && {
                                    path : "logo" , element :
                                         <UploadLogo />
                                 })
                              } ,
                              {
                                 ...(currentAdmin.isSuperAdmin && {
                                    path : "news-banner" , element :
                                         <NewsAndBanner />
                                 })
                              } ,
                        {
                           ...(currentAdmin.isSuperAdmin && {
                              path : "special-products" , element :
                                   <SpecialProducts />
                           })
                              } ,
                   
                 
                              {
                                 ...(currentAdmin.isSuperAdmin && {
                                    path : "contact-us" , element :
                                         <ContactUs />
                                 })
                              } ,
                              {
                                 ...(currentAdmin.isSuperAdmin && {
                                    path : "about-us" , element :
                                         <AboutUs />
                                 })
                              } ,
                              
                              {
                                 ...(currentAdmin.isSuperAdmin && {
                                    path : "social-media" , element :
                                         <SocialMedia />
                                 })
                              } ,
                              {
                                 ...(currentAdmin.isSuperAdmin && {
                                    path : "orders-level" , element :
                                         <SiteDataOrders />
                                 })
                              } ,
                            {
                               ...(currentAdmin.isSuperAdmin && {
                                  path : "banners" , element :
                                       <SiteDataBanners />
                               })
                              } ,
                              {
                                 ...(currentAdmin.isSuperAdmin && {
                                    path : "footer" , element :
                                         <Footer />
                                 })
                              } ,
                         ]
                    } ,
                    
                    {
                       
                         path     : "admins" ,
                         children : [
                              { element : <Navigate to = "/dashboard/admins/list" replace /> } ,
                              {...(currentAdmin.isSuperAdmin && { path : "list" , element : <AdminList /> }) } ,
                              {...(currentAdmin.isSuperAdmin && {path : "admin/new" , element : <AdminCreate />}) } ,
                              {...(currentAdmin.isSuperAdmin && { path : "admin/:name/edit" , element : <AdminCreate />}) } ,
                         
                         ]
                    } ,
                    
                    
                    {
                         path     : "e-commerce" ,
                         children : [
                              { element : <Navigate to = "/dashboard/e-commerce/list" replace /> } ,
                              {
                                   path : "list" , element :
                                             <EcommerceProductList />
                                        
                              } ,   {
                                   path : "discount" , element :
                                             <EcommerceDiscountList />
                                        
                              } ,
                            {...(currentAdmin.isSuperAdmin &&
                                    { path : "discount/new"
                                       , element : <EcommerceDiscountCreate />}) } ,
                            {...(currentAdmin.isSuperAdmin &&
                                    { path : "discount/:id/edit"
                                       , element : <EcommerceDiscountCreate />}) } ,
                              {
                                   path : "product/new" , element :
                                             <EcommerceProductCreate />
                              } ,
                              {
                                   path : "product/:id/edit" , element :
                                             <EcommerceProductCreate />
                                        
                              } ,
                              {...(currentAdmin.isSuperAdmin && { path : "categories/new"
                                    , element : <EcommerceCategoryCreate />}) } ,
                              {...(currentAdmin.isSuperAdmin && { path : "categories/:id/edit"
                                    , element : <EcommerceCategoryCreate />}) } ,
                              {...(currentAdmin.isSuperAdmin && { path : "categories/list"
                                 , element : <EcommerceCategoriesList /> })} ,
                              {...(currentAdmin.isSuperAdmin && { path : "sub-categories/list"
                                 , element : <EcommerceSuCategoriesList /> }) } ,
                              {...(currentAdmin.isSuperAdmin &&
                                      { path : "attributes/new"
                                 , element : <EcommerceAttributeCreate />}) } ,
                              {...(currentAdmin.isSuperAdmin && { path : "attributes/:id/edit"
                                 , element : <EcommerceAttributeCreate /> }) } ,
                              {...(currentAdmin.isSuperAdmin && { path : "attributes/list"
                                 , element : <EcommerceAttributesList /> }) } ,
                              {...(currentAdmin.isSuperAdmin && { path : "factories/new"
                                 , element : <EcommerceFactoryCreate />}) } ,
                              {...(currentAdmin.isSuperAdmin && { path : "factories/:id/edit"
                                 , element : <EcommerceFactoryCreate />}) } ,
                              {...(currentAdmin.isSuperAdmin && { path : "factories/list"
                                 , element : <EcommerceFactoriesList />}) } ,
                         ]
                    } ,
                    {
                         path     : "user" ,
                         children : [
                              { element : <Navigate to = "/dashboard/user/list" replace /> } ,
                              {...(currentAdmin.isSuperAdmin && { path : "list" , element : <UserList />}) } ,
                              {...(currentAdmin.isSuperAdmin && { path : "new" , element : <UserCreate />}) } ,
                              {...(currentAdmin.isSuperAdmin && { path : ":name/edit" , element : <UserCreate />}) } ,
                              { path : "account" , element : <UserAccount /> }
                         ]
                    } ,
                    {
                         path     : "report" ,
                         children : [
                              { element : <Navigate to = "/dashboard/report/list" replace /> } ,
                              {...(currentAdmin.isSuperAdmin && {
                                    path : "list" , element : <ReportsList />
                                 })
                              } ,
                         ]
                    } ,
                    {
                         path     : "projects" ,
                         children : [
                              { element : <Navigate to = "/dashboard/projects/list" replace /> } ,
                              {...(currentAdmin.isSuperAdmin && { path : "list"
                                    , element : <AboutUsProjectsLists />}) } ,
                              {...(currentAdmin.isSuperAdmin && { path : "new"
                                 , element : <SiteDataProjectCreate />}) } ,
                              {...(currentAdmin.isSuperAdmin && { path : ":id/edit"
                                 , element : <SiteDataProjectCreate />}) } ,
                         ]
                    } ,
                    {
                         path     : "faq" ,
                         children : [
                              { element : <Navigate to = "/dashboard/faq/list" replace /> } ,
                              {...(currentAdmin.isSuperAdmin && { path : "list"
                                 , element : <FaqsList />}) } ,
                              {...(currentAdmin.isSuperAdmin && { path : "new"
                                 , element : <FaqCreate />}) } ,
                              {...(currentAdmin.isSuperAdmin && { path : ":id/edit"
                                 , element : <FaqCreate />}) } ,
                         ]
                    } ,
                    {
                         path     : "expert" ,
                         children : [
                              { element : <Navigate to = "/dashboard/expert/list" replace /> } ,
                              {...(currentAdmin.isSuperAdmin && { path : "list"
                                    , element : <ExpertsList />}) } ,
                              {...(currentAdmin.isSuperAdmin && { path : "new" , element : <ExpertCreate />}) } ,
                              {...(currentAdmin.isSuperAdmin && { path : ":id/edit" , element : <ExpertCreate />}) } ,
                         ]
                    }         ,           {
                         path     : "page" ,
                         children : [
                              { element : <Navigate to = "/dashboard/expert/list" replace /> } ,
                              {...(currentAdmin.isSuperAdmin && { path : "list" , element : <PagesList />}) } ,
                              {...(currentAdmin.isSuperAdmin && { path : "new" , element : <PageCreate />}) } ,
                              {...(currentAdmin.isSuperAdmin && { path : ":id/edit" , element : <PageCreate />}) } ,
                         ]
                    } ,
                    {
                         path     : "blog" ,
                         children : [
                              { element : <Navigate to = "/dashboard/blog/posts" replace /> } ,
                              {
                                 ...(currentAdmin.isSuperAdmin && {
                                    path : "posts" , element :
                                         <BlogPosts />
                                 })
                              } ,
                              {
                                 ...(currentAdmin.isSuperAdmin && {
                                    path : "new-post" , element :
                                         <BlogNewPost />
                                 })
                              } ,
                              {
                                 ...(currentAdmin.isSuperAdmin && {
                                    path : "post/:id/edit" , element :
                                         <BlogNewPost />
                                 })
                              } ,
                              {
                                 ...(currentAdmin.isSuperAdmin && {
                                 path : "categories" , element : <BlogCategories />}) } ,
                              
                         ]
                    } ,
                    {
                         path     : "order" ,
                         children : [
                              { element : <Navigate to = "/dashboard/order/list" replace /> } ,
                              {
                                   path : "list" , element :
                                  <OrderList />
                              } ,
                              {
                                   path : ":id/edit" , element :
                                  <Order />
                              } ,
                              { path : ":id/edit-products-invoice" , element : <InvoiceProductsEdit /> } ,
                              
                              { path : "phone-order-list" , element : <PhoneOrdersList /> } ,
                              { path : "phone-order-list/:id" , element : <PhoneOrderEdit /> } ,
                         
                         ]
                    }
               
               ]
          } ,
          {
               path     : "/" ,
               element  : <MainLayout /> ,
               children : [ { element : <Navigate to = "/dashboard" replace /> } ]
          } ,
          // Main Routes
          {
               path     : "*" ,
               element  : <LogoOnlyLayout /> ,
               children : [
                    { path : "500" , element : <Page500 /> } ,
                    { path : "404" , element : <NotFound /> } ,
                    { path : "*" , element : <Navigate to = "/404" replace /> }
               ]
          } ,
          { path : "*" , element : <Navigate to = "/404" replace /> }
     ] );
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable( lazy( () => import("../pages/authentication/Login") ) );
const ResetPassword = Loadable( lazy( () => import("../pages/authentication/ResetPassword") ) );
const ForgotPassword = Loadable( lazy( () => import("../pages/authentication/ForgotPassword") ) )
const VerifyCode = Loadable( lazy( () => import("../pages/authentication/VerifyCode") ) );
// Dashboard
const GeneralEcommerce = Loadable( lazy( () => import("../pages/dashboard/GeneralEcommerce") ) );
const UploadLogo = Loadable( lazy( () => import("../pages/dashboard/SiteData/SiteDataUploadLogo") ) );
const NewsAndBanner = Loadable( lazy( () => import("../pages/dashboard/SiteData/SiteDataNewsAndBanner") ) );
const SpecialProducts = Loadable( lazy( () => import("../pages/dashboard/SiteData/SiteDataSpecialProducts") ) );
const AboutUs = Loadable( lazy( () => import("../pages/dashboard/SiteData/SiteDataAbout") ) );
const SiteDataProjectCreate = Loadable( lazy( () => import("../pages/dashboard/SiteData/SiteDataProjectCreate") ) );
const AboutUsProjectsLists = Loadable( lazy( () => import("../pages/dashboard/SiteData/SiteDataAboutUsProjectsLists") ) );
const ContactUs = Loadable( lazy( () => import("../pages/dashboard/SiteData/SiteDataContactUs") ) );
const SiteDataBanners = Loadable( lazy( () => import("../pages/dashboard/SiteData/SiteDataBanners") ) );
const Guide = Loadable( lazy( () => import("../pages/dashboard/SiteData/SiteDataGuide") ) );
const ADs = Loadable( lazy( () => import("../pages/dashboard/SiteData/SiteDataAds") ) );
const Footer = Loadable( lazy( () => import("../pages/dashboard/SiteData/SiteDataFooter") ) );
const SocialMedia = Loadable( lazy( () => import("../pages/dashboard/SiteData/SiteDataSocialMedia") ) );
const SiteDataOrders = Loadable( lazy( () => import("../pages/dashboard/SiteData/siteDataOrders") ) );
const EcommerceShop = Loadable( lazy( () => import("../pages/dashboard/Ecommerce/EcommerceShop") ) );
const EcommerceSuCategoriesList = Loadable( lazy( () => import("../pages/dashboard/Ecommerce/EcommerceSubCategoriesList") ) );
const EcommerceCategoriesList = Loadable( lazy( () => import("../pages/dashboard/Ecommerce/EcommerceCategoriesList") ) );
const EcommerceCategoryCreate = Loadable( lazy( () => import("../pages/dashboard/Ecommerce/EcommerceCategoryCreate") ) );
const EcommerceAttributesList = Loadable( lazy( () => import("../pages/dashboard/Ecommerce/EcommerceAttributesList") ) );
const EcommerceAttributeCreate = Loadable( lazy( () => import("../pages/dashboard/Ecommerce/EcommerceAttributeCreate") ) );
const EcommerceFactoriesList = Loadable( lazy( () => import("../pages/dashboard/Ecommerce/EcommerceFactoriesList") ) );
const EcommerceFactoryCreate = Loadable( lazy( () => import("../pages/dashboard/Ecommerce/EcommerceFactoryCreate") ) );
const EcommerceProductList = Loadable( lazy( () => import("../pages/dashboard/Ecommerce/EcommerceProductList") ) );
const EcommerceProductCreate = Loadable( lazy( () => import("../pages/dashboard/Ecommerce/EcommerceProductCreate") ) );
const InvoiceProductsEdit = Loadable( lazy( () => import("../components/_dashboard/order/order-info/InvoiceProductsEdit") ) );
const OrderList = Loadable( lazy( () => import("../pages/dashboard/Order/OrderList") ) );
const PhoneOrdersList = Loadable( lazy( () => import("../pages/dashboard/Order/PhoneOrdersList") ) );
const FaqCreate = Loadable( lazy( () => import("../pages/dashboard/FAQ/FaqCreate") ) );
const FaqsList = Loadable( lazy( () => import("../pages/dashboard/FAQ/FaqsList") ) );
const ExpertCreate = Loadable( lazy( () => import("../pages/dashboard/Expert/ExpertCreate") ) );
const ExpertsList = Loadable( lazy( () => import("../pages/dashboard/Expert/ExpertsList") ) );
const PageCreate = Loadable( lazy( () => import("../pages/dashboard/Page/PageCreate") ) );
const PagesList = Loadable( lazy( () => import("../pages/dashboard/Page/PageList") ) );
const PhoneOrderEdit = Loadable( lazy( () => import("../components/_dashboard/order/phone-orders/PhoneOrderEdit") ) );
const Order = Loadable( lazy( () => import("../pages/dashboard/Order/Order") ) );
const BlogPosts = Loadable( lazy( () => import("../pages/dashboard/Blog/BlogPosts") ) );
const BlogNewPost = Loadable( lazy( () => import("../pages/dashboard/Blog/BlogNewPost") ) );
const BlogCategories = Loadable( lazy( () => import("../pages/dashboard/Blog/BlogCategories") ) );
const AdminList = Loadable( lazy( () => import("../pages/dashboard/Admins/AdminList") ) );
const AdminCreate = Loadable( lazy( () => import("../pages/dashboard/Admins/AdminCreate") ) );
const UserList = Loadable( lazy( () => import("../pages/dashboard/User/UserList") ) );
const UserComments = Loadable( lazy( () => import("../pages/dashboard/User/UserComments") ) );
const BlogComments = Loadable( lazy( () => import("../pages/dashboard/User/BlogComments") ) );
const ReportsList = Loadable( lazy( () => import("../pages/dashboard/Report/ReportsList") ) );
const UserAccount = Loadable( lazy( () => import("../pages/dashboard/User/UserAccount") ) );
const UserCreate = Loadable( lazy( () => import("../pages/dashboard/User/UserCreate") ) );

// Main

const Page500 = Loadable( lazy( () => import("../pages/Page500") ) );
const NotFound = Loadable( lazy( () => import("../pages/Page404") ) );
