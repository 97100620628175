import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import { useRef , useState } from "react";
import homeFill from "@iconify/icons-eva/home-fill";
import settings2Fill from "@iconify/icons-eva/settings-2-fill";
import { Link as RouterLink , useNavigate } from "react-router-dom";
// material
import { alpha } from "@mui/material/styles";
import { Box , Button , Divider , MenuItem , Typography } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// hooks
import useAuth from "../../hooks/useAuth";
import useIsMountedRef from "../../hooks/useIsMountedRef";
// components
import { MIconButton } from "../../components/@material-extend";
import MyAvatar from "../../components/MyAvatar";
import MenuPopover from "../../components/MenuPopover";
import InstallPWA from "../../utils/installpwa";
import { useSelector } from "../../redux/store";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'خانه',
    icon: homeFill,
    linkTo: '/'
  },
  {
    label: 'تنظیمات',
    icon: settings2Fill,
    linkTo: PATH_DASHBOARD.user.account
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { currentAdmin } = useSelector((state) => state.admin);
  
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const { user, logout } = useAuth();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {currentAdmin.name}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}
        <Box  sx={{display:"flex" , justifyContent:"center",pb:.2,p: 2, pt: 1.5}}>
          <InstallPWA/>
        </Box>
        <Box sx={{ p: 2, pt:0 }}>
          <Button fullWidth  variant="contained" onClick={handleLogout}>
            خروج
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
