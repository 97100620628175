import * as Yup from "yup";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";
import { Form , FormikProvider , useFormik } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import closeFill from "@iconify/icons-eva/close-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import { IconButton , InputAdornment , Link , Stack , TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// routes
import { PATH_AUTH } from "../../../routes/paths";
// hooks
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
//
import { MIconButton } from "../../@material-extend";

// ----------------------------------------------------------------------

export default function LoginForm() {
     const { login } = useAuth();
     const isMountedRef = useIsMountedRef();
     const { enqueueSnackbar , closeSnackbar } = useSnackbar();
     const [ showPassword , setShowPassword ] = useState( false );
     
     const LoginSchema = Yup.object().shape( {
          email : Yup.string().email( "ایمیل باید یک ایمیل معتبر باشد" ).required( "ایمیل الزامی است" ) ,
          password : Yup.string().min( 8 , "رمز حداقل باید دارای 8 کاراکتر باشد" ).required( "رمزعبور الزامی است" )
     } );
     
     const formik = useFormik( {
          initialValues    : {
               email : "" ,
               password : "" ,
               remember : true
          } ,
          validationSchema : LoginSchema ,
          onSubmit         : async ( values , { setErrors , setSubmitting , resetForm } ) => {
               try {
                    const res   = await login( values.email , values.password );
                    if ( res.status === 200 ) {
                         enqueueSnackbar( " با موفقیت وارد شدید" , {
                              variant : "success" ,
                              action  : ( key ) => (
                                   <MIconButton size = "small" onClick = { () => closeSnackbar( key ) } >
                                        <Icon icon = { closeFill } />
                                   </MIconButton >
                              )
                         } );
                         resetForm();
                         
                    }
               
               } catch (error) {
                    enqueueSnackbar( error, { variant : "error" } )
                    if ( isMountedRef.current ) {
                         setSubmitting( false );
                    }
               }
          }
     } );
     
     const { errors , touched , values , isSubmitting , handleSubmit , getFieldProps } = formik;
     
     const handleShowPassword = () => {
          setShowPassword( ( show ) => !show );
     };
     
     return (
          <FormikProvider value = { formik } >
               <Form autoComplete = "off" noValidate onSubmit = { handleSubmit } >
                    <Stack spacing = { 3 } >
                         
                         <TextField
                              fullWidth
                              autoComplete = "username"
                              type = "text"
                              label = "ایمیل"
                              { ...getFieldProps( "email" ) }
                              error = { Boolean( touched.email && errors.email ) }
                              helperText = { touched.email && errors.email }
                         />
                         
                         <TextField
                              fullWidth
                              label = "رمز عبور"
                              autoComplete = "current-password"
                              type = { showPassword ? "text" : "password" }
                              { ...getFieldProps( "password" ) }
                              InputProps = { {
                                   endAdornment : (
                                        <InputAdornment position = "end" >
                                             <IconButton onClick = { handleShowPassword } edge = "end" >
                                                  <Icon icon = { showPassword ? eyeFill : eyeOffFill } />
                                             </IconButton >
                                        </InputAdornment >
                                   )
                              } }
                              error = { Boolean( touched.password && errors.password ) }
                              helperText = { touched.password && errors.password }
                         />
                    </Stack >
                    
                    <Stack direction = "row" alignItems = "center" justifyContent = "space-between"
                         sx = { { my : 2 } } >
                         {/*     <FormControlLabel
                          control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
                          label="مرا بخاطر بسپار"
                          />*/ }
                         
                         <Link component = { RouterLink } variant = "subtitle2"
                              to = { PATH_AUTH.resetPassword } >
                              رمزعبور را فراموش کرده اید؟
                         </Link >
                    </Stack >
                    
                    <LoadingButton fullWidth size = "large" type = "submit" variant = "contained"
                         loading = { isSubmitting } >
                         ورود
                    </LoadingButton >
               </Form >
          </FormikProvider >
     );
}
