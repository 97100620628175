import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box , Button , Card , Container , Link , Stack , Typography } from "@mui/material";
// routes
import { PATH_AUTH } from "../../routes/paths";
// hooks
import useAuth from "../../hooks/useAuth";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import { MHidden } from "../../components/@material-extend";
import { LoginForm } from "../../components/authentication/login";
import AuthFirebaseSocials from "../../components/authentication/AuthFirebaseSocial";
import LoginSVG from "../../components/loginSVG";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
     [theme.breakpoints.up('md')]: {
          display: 'flex'
     }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
     width: '100%',
     maxWidth: 464,
     display: 'flex',
     flexDirection: 'column',
     justifyContent: 'center',
     margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
     maxWidth: 480,
     margin: 'auto',
     display: 'flex',
     minHeight: '100vh',
     flexDirection: 'column',
     justifyContent: 'center',
     padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
     const { method, login } = useAuth();
     const handleLoginAuth0 = async () => {
          try {
               await login();
          } catch (error) {
               console.error(error);
          }
     };
     
     return (
          <RootStyle title="ورود">
               <AuthLayout>
               
               </AuthLayout>
               
               <MHidden width="mdDown">
                    <SectionStyle>
                     <LoginSVG/>
                    </SectionStyle>
               </MHidden>
               
               <Container maxWidth="sm">
                    <ContentStyle>
                         <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                              <Box sx={{ flexGrow: 1 }}>
                                   <Typography variant="h4" gutterBottom>
                                        ورود به پنل ادمین حداد
                                   </Typography>
                              </Box>
                         </Stack>
                         
                         {method !== 'auth0' ? (
                              <LoginForm />
                         ) : (
                              <Button fullWidth size="large" type="submit" variant="contained" onClick={handleLoginAuth0}>
                                   ورود
                              </Button>
                         )}
                    </ContentStyle>
               </Container>
          </RootStyle>
     );
}
