import { createSlice } from "@reduxjs/toolkit";
// utils
import axios , { default as ax } from "../../utils/axiosinstance";
import { getUser } from "./user";

const initialState = {
     isLoading : false ,
     error     : false ,
     ordersList    : [] ,
     order     : [] ,
     statistics     : {} ,
     invoice   : [] ,
     phoneOrders:[],
};

const slice = createSlice( {
     name     : "order" ,
     initialState ,
     reducers : {
          // START LOADING
          startLoading( state ) {
               state.isLoading = true;
          } ,
          
          // HAS ERROR
          hasError( state , action ) {
               state.isLoading = false;
               state.error = action.payload;
          } ,
          // GET ORDERS
          getOrdersSuccess( state , action ) {
               state.isLoading = false;
               state.ordersList = action.payload;
          } ,
          // GET STATISTICS
          getStatisticsSuccess( state , action ) {
               state.isLoading = false;
               state.statistics = action.payload;
          } ,
      
          // GET ORDER
          getOrderSuccess( state , action ) {
               state.isLoading = false;
               state.order = action.payload;
          } ,
          // GET INVOICE
          getInvoiceSuccess( state , action ) {
               state.isLoading = false;
               state.invoice = action.payload;
          } ,
     }
} );

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function getStatistics(startOfTheYear ) {
     return async ( dispatch ) => {
          dispatch( slice.actions.startLoading() );
          try {
               const response = await axios.get( `order/site/statistics?startOfTheYear=${startOfTheYear}` );
               dispatch( slice.actions.getStatisticsSuccess( response.data ) );
          } catch (error) {
               dispatch( slice.actions.hasError( error ) );
          }
     };
}


// ----------------------------------------------------------------------
export function getOrders(limit=1000,page=0 , sortBy='name',sortOrder='asc',search='') {
     return async ( dispatch ) => {
          dispatch( slice.actions.startLoading() );
          try {
               const response = await axios.get( `order?limit=${limit}&skip=${page}&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${search}` );
               dispatch( slice.actions.getOrdersSuccess( response.data ) );
          } catch (error) {
               dispatch( slice.actions.hasError( error ) );
          }
     };
}

export function getOrder( id ) {
     return async ( dispatch ) => {
          dispatch( slice.actions.startLoading() );
          try {
               const response = await axios.get( `order/${ id }` );
               dispatch( slice.actions.getOrderSuccess( response.data.order ) );
               dispatch( getInvoiceById( response.data.result.invoice ) )
          } catch (error) {
               dispatch( slice.actions.hasError( error ) );
          }
     };
}

// ----------------------------------------------------------------------
export function getPhoneOrders() {
     return async ( dispatch ) => {
          dispatch( slice.actions.startLoading() );
          try {
               const response = await axios.post( "order/getPhoneOrders" );
               dispatch( slice.actions.getPhoneOrdersSuccess( response.data.result ) );
          } catch (error) {
               dispatch( slice.actions.hasError( error ) );
          }
     };
}

export function changeStatus( id , num ) {
     return async ( dispatch ) => {
          dispatch( slice.actions.startLoading() );
          try {
               await axios.patch( "/order/status" , {
                    status : num ,
                    _ids   : [ id ]
               } )
               dispatch( getOrders() )
          } catch (error) {
               dispatch( slice.actions.hasError( error ) );
          }
     };
}

export function deleteOrder( id ) {
     return async ( dispatch ) => {
          dispatch( slice.actions.startLoading() );
          try {
               dispatch( getOrders() );
               
          } catch (error) {
               dispatch( slice.actions.hasError( error ) );
          }
     };
}


// ----------------------------------------------------------------------
export function getInvoiceById( id ) {
     return async ( dispatch ) => {
          dispatch( slice.actions.startLoading() );
          try {
               const response = await axios.get( `invoice/get?invoiceId=${ id }` );
               dispatch( slice.actions.getInvoiceSuccess( response.data.result ) );
          } catch (error) {
               dispatch( slice.actions.hasError( error ) );
          }
     };
}

// ----------------------------------------------------------------------


// CONFIRM PHONE ORDER

export function confirmPhoneOrder( id ) {
     return async ( dispatch ) => {
          dispatch( slice.actions.startLoading() );
          try {
               const response = await ax.post( `order/confirmPhoneOrders`,{phoneOrderId:id} )
               dispatch( getPhoneOrders() );
          } catch (error) {
               dispatch( slice.actions.hasError( error ) );
          }
     };
}

export function deletePhoneOrder( id ) {
     return async ( dispatch ) => {
          dispatch( slice.actions.startLoading() );
          try {
               const response = await ax.post( `order/deletPhoneOrders`,{phoneOrderId:id} )
               dispatch( getPhoneOrders() );
          } catch (error) {
               dispatch( slice.actions.hasError( error ) );
          }
     };
}


