import React , { useEffect , useState } from "react";
import { Button , Fade , Snackbar } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const InstallPWA = () => {
     const [ supportsPWA , setSupportsPWA ] = useState( true );
     const [ promptInstall , setPromptInstall ] = useState( null );
     const theme = useTheme();
     
     const [ state , setState ] = React.useState( {
          open : false ,
          Transition : Fade ,
     } );
     
     useEffect( () => {
          setTimeout( () => setState( { ...state , open : true } ) , 9000 )
          
          const handler = e => {
               e.preventDefault();
               console.log( "we are being triggered :D" );
               setSupportsPWA( true );
               setPromptInstall( e );
          };
          window.addEventListener( "beforeinstallprompt" , handler );
          
          return () => window.removeEventListener( "transitionend" , handler );
     } , [] );
     
     const onClick = evt => {
          if ( !promptInstall ) {
               return;
          }
          promptInstall.prompt();
          setState( { ...state , open : true } );
     };
     if ( !supportsPWA ) {
          return null;
     }
     const handleClose = () => {
          setState( {
               ...state ,
               open : false ,
          } );
     };
     
     return (
          <></>
        /*  <Button
               fullWidth
               id = "setup_button"
               aria-label = "Install app"
               title = "دانلود نسخه آفلاین"
               onClick = { onClick }
               variant = "contained"
               bodyStyle = { {
                    backgroundColor : theme.palette.mode === "light" ? theme.palette.grey[ 400 ] : theme.palette.grey[ 700 ] ,
                    color           : theme.palette.mode === "light" ? theme.palette.grey[ 700 ] : theme.palette.grey[ 400 ] ,
               } }
            /!*   open = { state.open }
               onClose = { handleClose }
               TransitionComponent = { state.Transition }*!/
               message = "برای استفاده از نسخه آفلاین سایت کلیک کنید"
          >
               نصب نسخه آفلاین
          
          </Button>*/
          /* <Button
           fullWidth
           onClick = { onClick }
           variant = "contained"
           bodyStyle = { {
           backgroundColor : theme.palette.mode === "light" ? theme.palette.grey[ 400 ] : theme.palette.grey[ 700 ] ,
           color           : theme.palette.mode === "light" ? theme.palette.grey[ 700 ] : theme.palette.grey[ 400 ] ,
           } }
           >
           نصب نسخه آفلاین
           </Button >*/
     );
};

export default InstallPWA;