import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import chartReducer from "./slices/chart";
import blogReducer from "./slices/blog";
import userReducer from "./slices/user";
import adminReducer from "./slices/admin";
import expertReducer from "./slices/expert";
import orderReducer from "./slices/order";
import reportReducer from "./slices/report";
import faqReducer from "./slices/faq";
import pageReducer from "./slices/page";
import productReducer from "./slices/product";
import dataReducer from "./slices/site-data";
import pwaReducer from "./slices/pwa";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  blog: blogReducer,
  user: userReducer,
  admin: adminReducer,
  order: orderReducer,
  faq: faqReducer,
  report: reportReducer,
  data: dataReducer,
  pwa: pwaReducer,
  page: pageReducer,
  expert: expertReducer,
  chart:chartReducer,
  product: persistReducer(productPersistConfig, productReducer)
});

export { rootPersistConfig, rootReducer };
