import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
import { default as ax } from "../../utils/axiosinstance";


// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  posts: [],
  post: null,
  recentPosts: [],
  hasMore: true,
  index: 0,
  isOpenModal      : false ,
  
  step: 11
};

const slice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET POST INFINITE
    getPostsInitial(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    getMorePosts(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // GET POST
    getPostSuccess(state, action) {
      state.isLoading = false;
      state.post = action.payload;
    },

    // GET RECENT POST
    getRecentPostsSuccess(state, action) {
      state.isLoading = false;
      state.recentPosts = action.payload;
    },
    // OPEN MODAL
    openModal( state ) {
      state.isOpenModal = true;
    } ,
  
    // CLOSE MODAL
    closeModal( state ) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    } ,
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMorePosts                ,   openModal , closeModal ,
             } = slice.actions;

// ----------------------------------------------------------------------

export function getAllPosts(limit=1000,page=1 , sortBy='title',sortOrder='asc',search='') {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ax.get(`blog?limit=${limit}&skip=${page}&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${search}`);
      dispatch(slice.actions.getPostsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPost(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ax.get(`blog/${id}`);
      dispatch(slice.actions.getPostSuccess(response.data.blog));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getRecentPosts(title) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/blog/posts/recent', {
        params: { title }
      });

      dispatch(slice.actions.getRecentPostsSuccess(response.data.recentPosts));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function deletePost(id ) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ax.post(`blog/delete?blogId=${id}`)
      dispatch( getAllPosts() );
      
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}