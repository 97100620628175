import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { useRef , useState } from "react";
import editFill from "@iconify/icons-eva/edit-fill";
import { Link as RouterLink } from "react-router-dom";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import qrcode from "@iconify/icons-ant-design/qrcode-outlined";
import moreVerticalFill from "@iconify/icons-eva/more-vertical-fill";
// material
import {
   IconButton ,
   ListItemIcon ,
   ListItemText ,
   Menu ,
   MenuItem
} from "@mui/material";
import QRCode from "react-qr-code";
// routes

// ----------------------------------------------------------------------

MoreMenu.propTypes = {
  onDelete: PropTypes.func,
  productName: PropTypes.string
};

export default function MoreMenu({hasDelete=true, onDelete, link,confirm,onConfirm,qrLink,name }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
   const qrCodeRef = useRef(null);
   
 /*  const downloadQRCode = () => {
      const dataURL = `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
           qrLink
      )}&size=200x200`;
      
      const linkElement = document.createElement('a');
      linkElement.href = dataURL;
      linkElement.download = `${name}_QRCode.png`;
      
      linkElement.setAttribute('download', `${name}_QRCode.png`);
      
      linkElement.click();
   };*/
   const downloadQRCode = () => {
      const svgElement = document.getElementById("QRCode");
      if (!svgElement) return;
      
      const svgData = new XMLSerializer().serializeToString(svgElement);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      
      img.onload = () => {
         canvas.width = img.width;
         canvas.height = img.height;
         ctx.drawImage(img, 0, 0);
         
         const pngFile = canvas.toDataURL("image/png");
         const downloadLink = document.createElement("a");
         downloadLink.download = `${name}_QRCode.png`;
         downloadLink.href = pngFile;
         downloadLink.click();
      };
      
      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
   };

   
   
   return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
     

        <MenuItem
          component={RouterLink}
          to={link}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="ویرایش" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
         {
              hasDelete &&
              <MenuItem onClick={onDelete} sx={{ color: 'text.secondary' }}>
                 <ListItemIcon>
                    <Icon icon={trash2Outline} width={24} height={24} />
                 </ListItemIcon>
                 <ListItemText primary="حذف" primaryTypographyProps={{ variant: 'body2' }} />
              </MenuItem>
         }
         {
              qrLink &&
              <MenuItem onClick={downloadQRCode} sx={{ color: 'text.secondary' }}>
                 <ListItemIcon>
                    <QRCode id="QRCode" title="Title" value={qrLink} />
                    {/*<Icon icon={qrcode} width={24} height={24} />*/}
                 </ListItemIcon>
                 <ListItemText primary="دانلود بارکد" primaryTypographyProps={{ variant: 'body2' }} />
                 
              </MenuItem>
         }
      
      </Menu>
    </>
  );
}
