import { createSlice } from "@reduxjs/toolkit";
// utils

import axios from "../../utils/axiosinstance";


// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  pages: [],
  page: null,
  recentPages: [],
  hasMore: true,
  index: 0,
  isOpenModal      : false ,
  
  step: 11
};

const slice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    
    getPagesSuccess(state, action) {
      state.isLoading = false;
      state.pages = action.payload;
    },
    
    getPagesInitial(state, action) {
      state.isLoading = false;
      state.pages = action.payload;
    },

    getMorePages(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // GET POST
    getPageSuccess(state, action) {
      state.isLoading = false;
      state.page = action.payload;
    },

    // GET RECENT POST
    getRecentPagesSuccess(state, action) {
      state.isLoading = false;
      state.recentPages = action.payload;
    },
    // OPEN MODAL
    openModal( state ) {
      state.isOpenModal = true;
    } ,
  
    // CLOSE MODAL
    closeModal( state ) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    } ,
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMorePages                ,   openModal , closeModal ,
             } = slice.actions;

// ----------------------------------------------------------------------

export function getAllPages(limit=1000,page=1 , sortBy='title',sortOrder='asc',search='') {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`page?limit=${limit}&skip=${page}&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${search}`);
      dispatch(slice.actions.getPagesSuccess(response.data));
    
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPage(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`page/${id}`);
      dispatch(slice.actions.getPageSuccess(response.data.page));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------



export function deletePage(id ) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`page/delete?pageId=${id}`)
      dispatch( getAllPages() );
      
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}