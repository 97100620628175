import { createSlice } from "@reduxjs/toolkit";
// utils
import { default as ax } from "../../utils/axiosinstance";

// ----------------------------------------------------------------------

const initialState = {
     isLoading: false,
     error: false,
     adminsList: [],
     currentAdmin:{},
     isOpenModal      : false ,
     admin:{},
     notifications: null
};

const slice = createSlice({
     name: 'admin',
     initialState,
     reducers: {
          // START LOADING
          startLoading(state) {
               state.isLoading = true;
          },
          
          // HAS ERROR
          hasError(state, action) {
               state.isLoading = false;
               state.error = action.payload;
          },
          // GET ADMIN
          getAdminSuccess(state, action) {
               state.isLoading = false;
               state.admin = action.payload;
          },
          // SET CURRENT ADMIN
          setCurrentAdmin(state, action) {
               state.isLoading = false;
               state.currentAdmin = action.payload;
          },
          
          // GET MANAGE USERS
          getAdminListSuccess(state, action) {
               state.isLoading = false;
               state.adminsList = action.payload;
          },
     
     
          // OPEN MODAL
          openModal( state ) {
               state.isOpenModal = true;
          } ,
     
          // CLOSE MODAL
          closeModal( state ) {
               state.isOpenModal = false;
               state.selectedEventId = null;
               state.selectedRange = null;
          } ,
          
    
     }
});

// Reducer
export default slice.reducer;

export const {  openModal , closeModal ,
                  setCurrentAdmin} = slice.actions;




// ----------------------------------------------------------------------


export function getAdminList( limit =1000000) {
     return async (dispatch) => {
          dispatch(slice.actions.startLoading());
          try {
               const response = await ax.get(`admin`);
               dispatch(slice.actions.getAdminListSuccess(response.data));
          } catch (error) {
               dispatch(slice.actions.hasError(error));
          }
     };
}


export function getAdmin(id) {
     return async (dispatch) => {
          dispatch(slice.actions.startLoading());
          try {
               const response = await ax.get(`admin/${id}`);
               dispatch(slice.actions.getAdminSuccess(response.data.admin));
          } catch (error) {
               
               dispatch(slice.actions.hasError(error));
          }
     };
}


export function deleteAdmin(userName) {
     return async (dispatch) => {
          dispatch(slice.actions.startLoading());
          try {
               await ax.post(`admin/deleteAdmin`,{userName});
          } catch (error) {
               dispatch(slice.actions.hasError(error));
          }
     };
}




