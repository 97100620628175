// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce')
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  siteData: {
    root: path(ROOTS_DASHBOARD, '/data'),
    uploadLogo: path(ROOTS_DASHBOARD, '/data/logo'),
    newsAndBanner: path(ROOTS_DASHBOARD, '/data/news-banner'),
    specialProducts: path(ROOTS_DASHBOARD, '/data/special-products'),
    headerSlider: path(ROOTS_DASHBOARD, '/data/header-slider'),
    contactUs: path(ROOTS_DASHBOARD, '/data/contact-us'),
    guide: path(ROOTS_DASHBOARD, '/data/guide'),
    socialMedia: path(ROOTS_DASHBOARD, '/data/social-media'),
    aboutUs: path(ROOTS_DASHBOARD, '/data/about-us'),
    banners: path(ROOTS_DASHBOARD, '/data/banners'),
    ordersLevel: path(ROOTS_DASHBOARD, '/data/orders-level'),
    footer: path(ROOTS_DASHBOARD, '/data/footer')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    comments: path(ROOTS_DASHBOARD, '/user/comments'),
    blogComments: path(ROOTS_DASHBOARD, '/user/blog-comments'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  report: {
    root: path(ROOTS_DASHBOARD, '/report'),
    list: path(ROOTS_DASHBOARD, '/report/list'),
  },
  faq: {
    root: path(ROOTS_DASHBOARD, '/faq'),
    list: path(ROOTS_DASHBOARD, '/faq/list'),
    newFaq: path(ROOTS_DASHBOARD, '/faq/new'),
  
  },
  projects: {
    root: path(ROOTS_DASHBOARD, '/projects'),
    newProjects: path(ROOTS_DASHBOARD, '/projects/new'),
    projectsLists: path(ROOTS_DASHBOARD, '/projects/list'),
  },
  expert: {
    root: path(ROOTS_DASHBOARD, '/expert'),
    list: path(ROOTS_DASHBOARD, '/expert/list'),
    newExpert: path(ROOTS_DASHBOARD, '/expert/new'),
    
  },
  admin: {
    root: path(ROOTS_DASHBOARD, '/admins'),
    list: path(ROOTS_DASHBOARD, '/admins/list'),
    newAdmin: path(ROOTS_DASHBOARD, '/admins/admin/new'),
  },

  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:id'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    discountsList: path(ROOTS_DASHBOARD, '/e-commerce/discount'),
    discount: path(ROOTS_DASHBOARD, '/e-commerce/discount/new'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    attributes: path(ROOTS_DASHBOARD, '/e-commerce/attributes/new'),
    attributesList: path(ROOTS_DASHBOARD, '/e-commerce/attributes/list'),
    categoriesList: path(ROOTS_DASHBOARD, '/e-commerce/categories/list'),
    subCategoriesList: path(ROOTS_DASHBOARD, '/e-commerce/sub-categories/list'),
    categories: path(ROOTS_DASHBOARD, '/e-commerce/categories/new'),
    factories: path(ROOTS_DASHBOARD, '/e-commerce/factories/new'),
    factoriesList: path(ROOTS_DASHBOARD, '/e-commerce/factories/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice')
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post'),
    categories: path(ROOTS_DASHBOARD, '/blog/categories'),
  
  },
  page: {
    root: path(ROOTS_DASHBOARD, '/page'),
    list: path(ROOTS_DASHBOARD, '/page/list'),
    page: path(ROOTS_DASHBOARD, '/page/:title'),
    newPage: path(ROOTS_DASHBOARD, '/page/new'),
    
  },
  order: {
    root: path(ROOTS_DASHBOARD, '/order'),
    list: path(ROOTS_DASHBOARD, '/order/list'),
    phoneList: path(ROOTS_DASHBOARD, '/order/phone-order-list'),
    orderEdit: path(ROOTS_DASHBOARD, '/order/attributes/new'),
  
  },
  seo: {
    root: path(ROOTS_DASHBOARD, '/seo'),
    
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
