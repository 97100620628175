import { createSlice } from "@reduxjs/toolkit";
// utils
import { default as ax } from "../../utils/axiosinstance";

// ----------------------------------------------------------------------

const initialState = {
     isLoading        : false ,
     error            : false ,
     productsList     : [] ,
     productsDiscountList     : [] ,
     currentProduct   : null ,
     factoriesList    : [] ,
     currentFactory   : {} ,
     attributesList   : [] ,
     isOpenModal      : false ,
     currentAttribute : null ,
     currentDiscount : null ,
     currentCategory  : null ,
     currentSubCategory  : null ,
     filters  : {
          orderByFilter:"unit",
          rowsPerPageFilter:null,
          accessFilter:null,
          pageFilter:0,
          orderFilter:"desc",
          filterNameFilter:"",
          SubcategorySelectFilter:"",
          factoryFilter:"all",
     } ,
     filterProperties   : [] ,
     categoriesList   : [] ,
     subCategoriesList : [] ,
     checkout         : {
          activeStep : 0 ,
          cart       : [] ,
          subtotal   : 0 ,
          total      : 0 ,
          shipping   : 0 ,
          billing    : null
     }
};

const slice = createSlice( {
     name     : "product" ,
     initialState ,
     reducers : {
          startLoading( state ) {
               state.isLoading = true;
          } ,
          endLoading( state ) {
               state.isLoading = false;
          } ,
          
          hasError( state , action ) {
               state.isLoading = false;
               state.error = action.payload;
          } ,
          
          getProductsSuccess( state , action ) {
               state.isLoading = false;
               state.productsList = action.payload;
          } ,
          
          getProductsDiscountSuccess( state , action ) {
               state.isLoading = false;
               state.productsDiscountList = action.payload;
          } ,
          
          getProductInfoSuccess( state , action ) {
               state.isLoading = false;
               state.productInfo = action.payload;
          } ,
          
          getProductSuccess( state , action ) {
               state.isLoading = false;
               state.currentProduct = action.payload;
          } ,
          
          getAttributesListSuccess( state , action ) {
               state.isLoading = false;
               state.attributesList = action.payload;
          } ,
          
          // GET PROPERTY SUCCESS
          getAttributeSuccess( state , action ) {
               state.isLoading = false;
               state.currentAttribute = action.payload;
          } ,
          // GET DISCOUNT SUCCESS
          getCurrentDiscountSuccess( state , action ) {
               state.isLoading = false;
               state.currentDiscount = action.payload;
          } ,
          // GET FACTORIES SUCCESS
          getFactoriesListSuccess( state , action ) {
               state.isLoading = false;
               state.factoriesList = action.payload;
          } ,
          
          // GET FACTORY SUCCESS
          getFactorySuccess( state , action ) {
               state.isLoading = false;
               state.currentFactory = action.payload;
          } ,
          
          //  SORT & FILTER PRODUCTS
          sortByProducts( state , action ) {
               state.sortBy = action.payload;
          } ,
          
          // GET CATEGORIES SUCCESS
          getCategorySuccess( state , action ) {
               state.isLoading = false;
               state.categoriesList = action.payload;
          } ,
          // GET SUBCATEGORIES SUCCESS
          getSubCategorySuccess( state , action ) {
               state.isLoading = false;
               state.subCategoriesList = action.payload;
          } ,
          
          // GET CATEGORY
          getCurrentCategorySuccess( state , action ) {
               state.isLoading = false;
               state.currentCategory = action.payload;
          } ,
          // GET SUBCATEGORY
          getCurrentSubCategorySuccess( state , action ) {
               state.isLoading = false;
               state.currentSubCategory = action.payload;
          } ,
 
          // GET Discount SUCCESS
          getDiscountSuccess( state , action ) {
               state.isLoading = false;
               state.discount = action.payload;
          } ,
          
          onNextStep( state ) {
               state.checkout.activeStep += 1;
          } ,
          onBackStep( state ) {
               state.checkout.activeStep -= 1;
          } ,
          resetCart( state ) {
               state.checkout.activeStep = 0;
          } ,
          setFilterProducts(state,action) {
               state.filters.accessFilter=action.payload.access
               state.filters.pageFilter=action.payload.page
               state.filters.rowsPerPageFilter=action.payload.rowsPerPage
               state.filters.orderByFilter=action.payload.orderBy
               state.filters.orderFilter=action.payload.order
               state.filters.factoryFilter=action.payload.factory
               state.filters.factorySelectFilter=action.payload.factorySelect
               state.filters.SubcategorySelectFilter=action.payload.subcategorySelect
               state.filters.filterNameFilter=action.payload.filterName
          },
          openModal( state ) {
               state.isOpenModal = true;
          } ,
          
          // CLOSE MODAL
          closeModal( state ) {
               state.isOpenModal = false;
               state.selectedEventId = null;
               state.selectedRange = null;
          } ,
          handleFilterProperties     : ( state , action ) => {
               state.filterProperties = action.payload;
               
          } ,
     } ,
     
     
} );

// Reducer
export default slice.reducer;

// Actions
export const {
                  onNextStep ,
                  onBackStep ,
                  resetCart ,
                  openModal , closeModal ,
                  setFilterProducts ,filterProducts,handleFilterProperties,
                  sortByProducts ,
             } = slice.actions;

// ----------------------------------------------------------------------

export function getProducts( limit=1000,page=0, sortBy='updatedAt',sortOrder='asc',search='',access='all'
                             ,factoryProduct='all',filterProperties,subcategorySelect) {
     return async ( dispatch,getState ) => {
          dispatch( slice.actions.startLoading() );
          const { filterProperties } = getState().product;
          const createQueryString = (filterProps) => {
               const queryString = Object.entries(filterProps)
                                         .sort((a, b) => a[0].localeCompare(b[0]))
                                         .map(([key, value]) => `${encodeURIComponent(key)}:${encodeURIComponent(value)}`)
                                         .join(',');
               
               return queryString;
          };
          const queryString = createQueryString(filterProperties)
          try {
               let response =[]
               if(queryString){
                    response = await ax.get( `product/fill/selectize?subcategoryName=${subcategorySelect}&properties=${(queryString)}&factoryName=${factoryProduct}&skip=${page}&limit=1000` );
               }
              else if(access !== 'all'){
                 response = await ax.get( `product?access=${access}&sortBy=${sortBy}&search=${search}&sortOrder=${sortOrder}&factorySubcategory=all&factoryProduct=${factoryProduct}&skip=${page}` );
           }else if(access === 'all'){
                 response = await ax.get( `product?access=${access}&sortBy=${sortBy}&search=${search}&sortOrder=${sortOrder}&factorySubcategory=all&factoryProduct=${factoryProduct}&skip=${page}&limit=${limit}` );
                if(response.data.products.length >  response.data.count )
                {
                     response = await ax.get( `product?access=${access}&sortBy=${sortBy}&search=${search}&sortOrder=${sortOrder}&factorySubcategory=all&factoryProduct=${factoryProduct}&skip=${page}` );
                }
           }
               dispatch( slice.actions.getProductsSuccess( response.data ) );
          } catch (error) {
               dispatch( slice.actions.hasError( error ) );
          }
     };
}



// ----------------------------------------------------------------------
// GET Discounts
export function getDiscount( lan ) {
     return async ( dispatch ) => {
          dispatch( slice.actions.startLoading() );
          try {
               const response = await ax.get( `discount` )
               dispatch( slice.actions.getProductsDiscountSuccess( response.data ) );
          } catch (error) {
               dispatch( slice.actions.hasError( error ) );
          }
     };
}
// ----------------------------------------------------------------------

export function getCurrentProduct( id ) {
     return async ( dispatch ) => {
          dispatch( slice.actions.startLoading() );
          try {
               const response = await ax.get( `product/${ id }` );
               dispatch( slice.actions.getProductSuccess( response.data.product ) );
     
          } catch (error) {
               dispatch( slice.actions.hasError( error ) );
          }
     };
}


// DELETE PRODUCT

export function deleteProduct( id ) {
     return async ( dispatch ) => {
          dispatch( slice.actions.startLoading() );
          try {
               await ax.post( `product/deleteProduct` , { products :[id] } )
               dispatch( getProducts() );
               
          } catch (error) {
               dispatch( slice.actions.hasError( error ) );
          }
     };
}

export function getCategory(limit=1000,page=0, sortBy='name',sortOrder='asc',search='') {
     return async ( dispatch ) => {
          dispatch( slice.actions.startLoading() );
          try {
               const response = await ax.get(`category?limit=${limit}&skip=${page}&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${search}`);
               dispatch( slice.actions.getCategorySuccess( response.data ) );
          } catch (error) {
               dispatch( slice.actions.hasError( error ) );
          }
     };
}

export function getSubCategory(limit=1000,page=0 , sortBy='name',sortOrder='asc',search='') {
     return async ( dispatch ) => {
          dispatch( slice.actions.startLoading() );
          try {
               const response = await ax.get(`subcategory?limit=${limit}&skip=${page}&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${search}`);
               dispatch( slice.actions.getSubCategorySuccess( response.data ) );
          } catch (error) {
               dispatch( slice.actions.hasError( error ) );
          }
     };
}



export function getCurrentCategory( id ) {
     return async ( dispatch ) => {
          dispatch( slice.actions.startLoading() );
          try {
               const response = await ax.get( `category/${ id }` );
               dispatch( slice.actions.getCurrentCategorySuccess( response.data.
                    category ) );
          } catch (error) {
               dispatch( slice.actions.hasError( error ) );
          }
     };
}
export function getCurrentSubCategory( id ) {
     return async ( dispatch ) => {
          dispatch( slice.actions.startLoading() );
          try {
               const response = await ax.get( `subcategory/${ id }` );
               dispatch( slice.actions.getCurrentSubCategorySuccess( response.data.
                    subcategory ) );
          } catch (error) {
               dispatch( slice.actions.hasError( error ) );
          }
     };
}


export function deleteCategory( id ) {
     return async ( dispatch ) => {
          dispatch( slice.actions.startLoading() );
          try {
               const response = await ax.post( `product/deleteCategory` , { id } );
               dispatch( getCategory() );
               
          } catch (error) {
               dispatch( slice.actions.hasError( error ) );
          }
     };
     
}


// GET PROPERTIES
export function getAttributes( limit=1000,page=0 , sortBy='name',sortOrder='asc',search='' ) {
     return async ( dispatch ) => {
          dispatch( slice.actions.startLoading() );
          try {
               const response = await ax.get( `property?limit=${limit}&skip=${page}&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${search}` )
               dispatch( slice.actions.getAttributesListSuccess( response.data ) );
               
          } catch (error) {
               dispatch( slice.actions.hasError( error ) );
          }
     };
}


// GET PROPERTY
export function getAttribute( id ) {
     return async ( dispatch ) => {
          dispatch( slice.actions.startLoading() );
          try {
               const response = await ax.get( `property/${ id }` )
               dispatch( slice.actions.getAttributeSuccess( response.data.property ) );
          } catch (error) {
               dispatch( slice.actions.hasError( error ) );
          }
     };
}
export function getCurrentDiscount( id ) {
     return async ( dispatch ) => {
          dispatch( slice.actions.startLoading() );
          try {
               const response = await ax.get( `discount/${ id }` )
               
               dispatch( slice.actions.getCurrentDiscountSuccess( response.data.discount ) );
          } catch (error) {
               dispatch( slice.actions.hasError( error ) );
          }
     };
}


// DELETE PROPERTY
export function deleteAttri( id ) {
     return async ( dispatch ) => {
          dispatch( slice.actions.startLoading() );
          try {
               const response = await ax.post( `product/deleteProperty` , {
                    id
               } )
               
          } catch (error) {
               dispatch( slice.actions.hasError( error ) );
          }
     };
}


// GET FACTORIES
export function getFactories( lan ) {
     return async ( dispatch ) => {
          dispatch( slice.actions.startLoading() );
          try {
               const response = await ax.get( `factory` )
               dispatch( slice.actions.getFactoriesListSuccess( response.data ) );
          } catch (error) {
               dispatch( slice.actions.hasError( error ) );
          }
     };
}


// GET FACTORY
export function getFactory( id ) {
     return async ( dispatch ) => {
          dispatch( slice.actions.startLoading() );
          try {
               const response = await ax.get( `factory/${ id }` )
               dispatch( slice.actions.getFactorySuccess( response.data.factory ) );
               
          } catch (error) {
               dispatch( slice.actions.hasError( error ) );
          }
     };
}


// DELETE FACTORY
export function deleteFactory( id ) {
     return async ( dispatch ) => {
          dispatch( slice.actions.startLoading() );
          try {
               await ax.delete( `factories/${ id }` )
               dispatch( slice.actions.endLoading() );
               dispatch( getFactories() )
          } catch (error) {
               dispatch( slice.actions.hasError( error ) );
          }
     };
}
