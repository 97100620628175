import { createSlice } from "@reduxjs/toolkit";
// utils
import { default as ax } from "../../utils/axiosinstance";

// ----------------------------------------------------------------------

const initialState = {
     isLoading: false,
     error: false,
     isOpenModal      : false ,
     faqsList: [],
     currentFAQ: {},
};

const slice = createSlice({
     name: 'faq',
     initialState,
     reducers: {
          // START LOADING
          startLoading(state) {
               state.isLoading = true;
          },
          
          // HAS ERROR
          hasError(state, action) {
               state.isLoading = false;
               state.error = action.payload;
          },
          
          
          // GET FAQs
          getFAQListSuccess(state, action) {
               state.isLoading = false;
               state.faqsList = action.payload;
          },
          // GET CURRENT FAQs
          getCurrentFAQSuccess(state, action) {
               state.isLoading = false;
               state.currentFAQ = action.payload;
          },
          // OPEN MODAL
          openModal( state ) {
               state.isOpenModal = true;
          } ,
     
          // CLOSE MODAL
          closeModal( state ) {
               state.isOpenModal = false;
               state.selectedEventId = null;
               state.selectedRange = null;
          } ,
     }
});

// Reducer
export default slice.reducer;

// Actions
export const {  openModal , closeModal ,onToggleFollow, deleteUser ,setCurrentUser} = slice.actions;




// ----------------------------------------------------------------------

export function getFAQList( limit =1000000) {
     return async (dispatch) => {
          dispatch(slice.actions.startLoading());
          try {
               const response = await ax.get(`site-info/contact-us/question-and-answer`);
               dispatch(slice.actions.getFAQListSuccess(response.data));
          } catch (error) {
               dispatch(slice.actions.hasError(error));
          }
     };
}
// ----------------------------------------------------------------------

export function getCurrentFAQ( id ) {
     return async (dispatch) => {
          dispatch(slice.actions.startLoading());
          try {
               const response = await ax.get(`site-info/contact-us/question-and-answer/${id}`);
               dispatch(slice.actions.getCurrentFAQSuccess(response.data));
          } catch (error) {
               dispatch(slice.actions.hasError(error));
          }
     };
}
