import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axiosinstance";

const initialState = {
  isLoading: false,
  error: false,
  siteDataList: [],
  logo:null,
  socialMedia:[],
  banners:[],
  orderStep:[],
  footer:{},
  specialProducts:{},
  isOpenModal      : false ,
  currentProject:{},
  projectsLists:{},
  newsAndBanner:{},
  aboutUs:{},
  contactUs:{},
};

const slice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getSiteDataSuccess(state, action) {
      state.isLoading = false;
      state.siteLogoList = action.payload ;
    },
  
    // GET LOGO
    getSiteDataLogoSuccess(state, action) {
      state.isLoading = false;
      state.logo = action.payload ;
    },
    // GET NEWS&BANNER
    getSiteDataNewsAndBannerSuccess(state, action) {
      state.isLoading = false;
      state.newsAndBanner = action.payload ;
    },
    // GET SOCIALMEDIA
    getSiteDataSocialMediaSuccess(state, action) {
      state.isLoading = false;
      state.socialMedia = action.payload ;
    },
    // GET PROJECTS
    getAboutUsProjectsSuccess(state, action) {
      state.isLoading = false;
      state.projectsLists = action.payload ;
    },
    // GET PROJECT
    getAboutUsCurrentProjectsSuccess(state, action) {
      state.isLoading = false;
      state.currentProject = action.payload ;
    },
    // GET BANNERS
    getSiteDataBannersSuccess(state, action) {
      state.isLoading = false;
      state.banners = action.payload ;
    },
    // GET ORDERSTEP
    getSiteDataOrderStepSuccess(state, action) {
      state.isLoading = false;
      state.orderStep = action.payload ;
    },
    // GET SPECIALPRODUCTS
    getSiteDataSpecialProductsSuccess(state, action) {
      state.isLoading = false;
      state.specialProducts = action.payload ;
    },
    // GET FOOTER
    getSiteDataFooterSuccess(state, action) {
      state.isLoading = false;
      state.footer = action.payload ;
    },
    // GET ABOUTUS
    getAboutUsSuccess(state, action) {
      state.isLoading = false;
      state.aboutUs = action.payload ;
    },
    getContactUsSuccess(state, action) {
      state.isLoading = false;
      state.contactUs = action.payload ;
    },
    // OPEN MODAL
    openModal( state ) {
      state.isOpenModal = true;
    } ,
  
    // CLOSE MODAL
    closeModal( state ) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    } ,
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
              
               openModal , closeModal ,
            
             } = slice.actions;

// ----------------------------------------------------------------------
export function getSiteData(type) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`siteData/get?type=${type}`);
      dispatch(slice.actions.getSiteDataSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getSiteDataLogo(type) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`site-info/main-page/logo`);
      dispatch(slice.actions.getSiteDataLogoSuccess(response.data.logo));
     
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}// ----------------------------------------------------------------------
export function getSiteDataNewsAndBanner(type) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`site-info/main-page/news-and-banner`);
      dispatch(slice.actions.getSiteDataNewsAndBannerSuccess(response.data.newsAndBanner
      ));
     
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------
export function getSiteDataSocialMedia(type) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`site-info/main-page/social-network`);
      dispatch(slice.actions.getSiteDataSocialMediaSuccess(response.data.socialNetworks));
     
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------
export function getSiteDataOrderStep(type) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`site-info/main-page/order-step`);
      dispatch(slice.actions.getSiteDataOrderStepSuccess(response.data.orderSteps
      ));
     
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------
export function getSiteDataBanners(type) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`site-info/main-page/gallery-step`);
      dispatch(slice.actions.getSiteDataBannersSuccess(response.data.gallerySteps));
     
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------
export function getSiteDataSpecialProducts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`site-info/main-page/special-products`);
      dispatch(slice.actions.getSiteDataSpecialProductsSuccess(response.data.specialProducts ));
      
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getSiteDataFooter() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`site-info/main-page/footer`);
      dispatch(slice.actions.getSiteDataFooterSuccess(response.data.footer ));
     
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------
export function getAboutUs(type) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`site-info/about-us/page-content`);
      dispatch(slice.actions.getAboutUsSuccess(response.data.pageContent
      ));
     
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------
export function getAboutUsProjects(limit=1000,page=0, sortBy='name',sortOrder='asc',search='') {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`site-info/about-us/project?limit=${limit}&skip=${page}&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${search}`);
      dispatch(slice.actions.getAboutUsProjectsSuccess(response.data ));

      
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------
export function getAboutUsCurrentProject(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`site-info/about-us/project/${id}`);
      dispatch(slice.actions.getAboutUsCurrentProjectsSuccess(response.data.project
      ));
      
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getContactUs(type) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`site-info/contact-us/page-content`);
      dispatch(slice.actions.getContactUsSuccess(response.data.
           pageContent ));
      
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

